import 'url-polyfill'
import * as rt from '../runtime'

export function onDomLoaded (listener) {
  if (rt.document.readyState !== 'loading') listener();
  else rt.document.addEventListener('DOMContentLoaded', listener);
}

export function queryParam (key) {
  return new URL(rt.window.location.href).searchParams.get(key);
}
