import { propTypes as $p, React, underscore as _ } from '../../deps'
import * as fmt from '../util/fmt'
import {info} from '../img';

export function Answer (props) {
  let {children, htmlFor, index, label, style, numberStyle, labelStyle} = props;
  return (
    <label htmlFor={htmlFor}
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        padding: '16px 0',
        wordBreak: 'break-word',
        ...style
      }}>

      <div
        style={{
          fontSize: 32,
          width: 48,
          ...numberStyle
        }}
      >

        {fmt.num(index + 1)}

      </div>

      <div className='flex'
        style={{
          alignItems: 'center',
          alignSelf: 'stretch',
          display: 'flex',
          flex: 1,
          flexDirection: 'row'
        }}>

        <span style={labelStyle}>{label}</span>

      </div>

      {children}

    </label>
  );
}
export {Answer as default};

export function Bonus (props) {
  let {children, style, imgStyle, textStyle} = props;
  return (
    <div className='flex'
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        padding: '16px 0',
        ...style
      }}>

      <div className='flex'
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}>

        <img
          alt="information"
          src={info.src}
          style={{
            height: info.height / 2,
            width: info.width / 2,
            marginRight: '16px',
            ...imgStyle
          }}/>

      </div>

      <div className='flex'
        style={{
          alignItems: 'center',
          alignSelf: 'stretch',
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          fontStyle: 'italic',
          ...textStyle
        }}
      >

        {children}

      </div>

    </div>
  );
}
Answer.Bonus = Bonus;
