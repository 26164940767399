import { underscore as _ } from '../../deps';

export {sortedPairs} from '../../util';
export {toPathKeyed} from '../../../cloudfn/util';

/** return object mapping values to arrays of keys */
export function invertGrouped (obj) {
  return _.reduce(obj, (res, v, k) => {
    res[v] = res[v] || [];
    res[v].push(k);
    return res;
  }, {});
}

export function now () { return Date.now(); }

export function shuffleSeeded (array, seed) {
  let res = Array.from(array);
  let nextInt = newRandomIntSeededFn(seed);
  for (let i = res.length - 1; i > 0; i--) {
    let j = nextInt(i + 1);
    let v = res[j];
    res[j] = res[i];
    res[i] = v;
  }
  return res;
}

function newRandomIntSeededFn (seed) {
  let randFn = newRandomSeededFn(seed);
  return function (bound) {
    if (bound == null || !Number.isInteger(bound)
      || bound > (0x7fffffff) || bound < 1)
    {
      throw new Error('invalid bound ' + bound);
    }
    return Math.floor(randFn() * bound);
  }
}

function newRandomSeededFn (seed) {
  // burn the first few
  seed = randomIntSeeded(seed);
  seed = randomIntSeeded(seed);
  seed = randomIntSeeded(seed);
  return function () {
    seed = randomIntSeeded(seed);
    return seed / 0x7fffffff;
  }
}

function randomIntSeeded (seed) {
  // via https://en.wikipedia.org/wiki/Xorshift
  // HACK limit to 31 bits (i.e. positive int)
  let x = (seed === 0 ? 1 : seed) & 0x7fffffff;
  x = (x ^ ((x << 13) & 0x7fffffff));
  x = (x ^ ((x >> 17) & 0x7fffffff));
  x = (x ^ ((x <<  5) & 0x7fffffff));
  return x;
}
