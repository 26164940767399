import * as Comp from './component'
import {gramaphone, point0Big, point1Big } from './img';
import { propTypes as $p, React, underscore as _ } from '../deps'
import { factVoteKey } from './util/app'
import { PointRow } from './RoundEnd'
import * as fmt from './util/fmt'
import ReactGA from 'react-ga';

export default class AnnounceVote extends React.Component {
  constructor (props) {
    super(props);
    _.bindAll(this, 'handleClickDoneTruth', 'handleClickDoneLie');
  }

  handleClickDoneTruth () {
    const { card, cardSetName, onDone, players, voteInfoPair, isGameOver } = this.props;
    const { topic } = card;
    const truthPercentage = ( (voteInfoPair[1].length / _.size(players)) * 100 )
    ReactGA.event({
      category: 'Cards',
      action: `Card-Truth-${cardSetName}-${topic}`,
      label: `Card Truth: ${cardSetName}-${topic}`,
      value: truthPercentage
    });
    if (isGameOver) {
      ReactGA.event({
        category: 'Games',
        action: 'Game-End',
        label: 'End Game',
        value: _.size(players)
      });
    }
    onDone();
  }
  handleClickDoneLie () {
    const { answers, card, cardSetName, onDone, players, voteInfoPair } = this.props;
    const { topic } = card;
    let voteKey = voteInfoPair[0];
    let lie = answers[voteKey];
    let votePlayers = _.pick(players, ...(voteInfoPair[1]));
    ReactGA.event({
      category: 'Lies',
      action: `Lie-${cardSetName}-${topic}-${lie}`,
      label: `Lie: ${cardSetName}-${topic}-${lie}`,
      value: _.size(votePlayers)
    });
    onDone();
  }
  render () {
    let players = this.props.players;
    let announcerPlayer = players[this.props.announcerPlayerKey];
    let isAnnouncer = this.props.selfPlayerKey === this.props.announcerPlayerKey;
    let voteAnswerIndex = this.props.voteAnswerIndex;
    let voteKey = this.props.voteInfoPair[0];
    let votePlayers = _.pick(this.props.players, ...(this.props.voteInfoPair[1]));

    return (
      <Comp.Main
        className={`AnnounceVote ${isAnnouncer ? '' : 'await'}`}
        overlay={<Comp.OfflineOverlay disabled={this.props.connected} />}
      >

        <Comp.Header style={{marginBottom: 32}}/>

        {(!isAnnouncer) ? (
          <React.Fragment>

            <h1 style={{marginBottom: 48}}>
              <Comp.Player player={announcerPlayer}/>{' '}
              will read the answers
            </h1>

            <img src={gramaphone.src}
              style={{
                alignSelf: 'center',
                height: gramaphone.height / 2,
                maxWidth: '50%',
                width: gramaphone.width / 2
              }}/>

          </React.Fragment>
        ) :
        (voteKey === factVoteKey) ? (
          <React.Fragment>

            <h1 style={{marginBottom: 48}}>
              {(_.size(votePlayers) < 1) ? (
                'Nobody guessed'
              ) : (
                <React.Fragment>
                  <Comp.PlayerSeries players={votePlayers}/>{' '}
                  guessed
                </React.Fragment>
              )}
            </h1>

            <Comp.Answer
              index={voteAnswerIndex}
              label={this.props.typoRound && this.props.card.typo
              ? this.props.card.typo
              : this.props.card.fact
              }
            />

            {(!this.props.card.bonus) ? null : (
              <Comp.Answer.Bonus>
                {this.props.card.bonus}
              </Comp.Answer.Bonus>
            )}

            <Comp.Hr style={{marginBottom: 32, marginTop: 32}}/>
            
            <p style={{marginBottom: 32}}><strong>Which was the truth!</strong></p>

            {(_.size(votePlayers) < 1) ? (
              <PointRow
                pointStyle={{opacity: 0.333}}
                pointImg={point1Big}
                style={{
                  alignSelf: 'center'
                }}
              />
            ) : (
              <PointRow
                pointLabel={1}
                pointImg={point1Big}
                style={{
                  alignSelf: 'center'
                }}
              />
            )}

            <input
              type="button"
              value="I have read this out loud"
              onClick={this.handleClickDoneTruth}/>

          </React.Fragment>
        ) : (
          <React.Fragment>

            <h1 style={{marginBottom: 48}}>
              <Comp.PlayerSeries players={votePlayers}/>{' '}
              guessed
            </h1>


            <Comp.Answer index={voteAnswerIndex}
              label={this.props.answers[voteKey]}/>

            <Comp.Hr style={{marginBottom: 32, marginTop: 32}}/>

            <p style={{marginBottom: 32}}><strong>Which was a lie told by{' '}<Comp.Player player={this.props.players[voteKey]}/>.</strong></p>

            <PointRow
              pointLabel={fmt.num(Object.keys(votePlayers).length)}
              pointImg={point0Big}
              style={{
                alignSelf: 'center'
              }}
            />

            <input
              type="button"
              value="I have read this out loud"
              onClick={this.handleClickDoneLie}/>

          </React.Fragment>
        )}

      </Comp.Main>
    );
  }
}
AnnounceVote.displayName = `AnnounceVote`;
