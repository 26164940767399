import { propTypes as $p, React, underscore as _ } from '../../deps'

export function Hr (props) {
  return (
    <hr {...props}
      style={{
        ...props.style
      }}/>
  );
}
Hr.displayName = `Hr`;
