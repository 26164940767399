import * as Comp from './component'
import { propTypes as $p, React, underscore as _ } from '../deps'

export default class EnterName extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      name: '',
      error: null
    };
    _.bindAll(this, 'handleChange', 'handleSubmit');
  }
  handleChange (e) {
    this.setState({ name: e.target.value });
  }
  handleSubmit (e) {
    e.preventDefault();
    if (this.state.name === '') {
      this.setState({error: 'You must enter a name'});
    }
    else this.props.onSubmitName(this.state.name);
  }
  render () {
    let {props} = this;
    let {roomCreatedAt, selfPlayerIndex} = props;
    return (
      <Comp.Main className='EnterName'
        overlay={<Comp.OfflineOverlay disabled={this.props.connected}/>}
      >

        <Comp.Header/>

        <Comp.RoomCodeHeader {...this.props}
          style={{marginBottom: 0}}/>

        <Comp.Avatar 
          playerIndex={selfPlayerIndex}
          scale={3/2}
          style={{
            alignSelf: 'center',
            marginBottom: 16
          }}
          {...{roomCreatedAt}}/>

        <h1>
          Enter your name
        </h1>

        <form onSubmit={this.handleSubmit}
          style={{marginBottom: 16}}>

          <input className={Comp.errorClass(this)}
            placeholder='Name'
            style={{marginBottom: 32}}
            type='text'
            value={this.state.name}
            maxLength={28}
            onChange={this.handleChange}
          />

          <Comp.ErrorInfo error={this.state.error}
            style={{marginBottom: 32}}/>

          <input type='submit'
            value='START GAME'/>

        </form>

      </Comp.Main>
    );
  }
}
EnterName.displayName = `EnterName`;
