import { underscore as _ } from '../deps'
import { Component } from '../component'
import { React } from '../deps'

export default function withConnected (connectedModel) {
  return (Wrapped) => {
    class Connected extends Component {
      constructor (props) {
        super(props);
        this.state = {connected: null};
        _.bindAll(this, 'handleUpdateConnected');
      }
      componentDidMount () {
        connectedModel.setOnUpdate(this.handleUpdateConnected);
      }
      componentWillUnmount () { connectedModel.removeDbListeners(); }
      handleUpdateConnected (connected) {
        console.log('connected=' + connected);
        this.setState({connected});
      }
      render () {
        return <Wrapped
          connected={this.state.connected}
          {...this.props}/>;
      }
    }
    Connected.displayName = `Connected(${Wrapped.displayName})`;
    return Connected;
  };
}
