import * as rt from '../../runtime';

export function resetScroll () { rt.document.body.scrollTo(0, 0) }

export function setAwait (on) {
  rt.document.body.classList.toggle('await', on);
}

export function setOverlay (on) {
  rt.document.body.classList.toggle('overlay', on);
}

export function has (attr, cl) {
  return cl && (` ${attr || ''} `.indexOf(` ${cl} `) >= 0);
}
