import { propTypes as $p, React, underscore as _ } from '../../deps';
import {setOverlay} from '../util/runtime';
import './Overlay.css'
import "wicg-inert";

export class Overlay extends React.Component {
  componentDidMount () { this.updateOverlay(this.props.disabled) }
  componentWillUnmount () { this.updateOverlay(true) }
  componentDidUpdate(prevProps) {
    let {props: {disabled}} = this;
    if (!!disabled !== !!prevProps.disabled) {
      this.updateOverlay(disabled);
    }
  }
  updateOverlay(disabled) {
    setOverlay(!disabled);
    const mainContent = document.getElementById('main-content');
    if (disabled) {
      mainContent.inert = false;
    } else {
      mainContent.inert = true;
    }
  }
  render () {
    let {children, className, disabled, style} = this.props;
    return (
      <div className={`Overlay ${disabled ? 'hidden' : ''} ${className || ''}`}
        style={style}>

        <div className='Overlay-content'>

          {children}

        </div>

      </div>
    );
  }
}
Overlay.displayName = 'Overlay';
