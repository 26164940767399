import firebase from '../../firebase'
import { RoomModel } from './roomModel'
import { UserModel } from './userModel'
import { underscore as _ } from '../../deps'

export function createModel (dbRootKey) {
  let db = firebase.database().ref().child(dbRootKey);
  return new Model(db);
}

export class Model {
  constructor (db) {
    this.db = db;
    this._room = null;
    this._user = null;
  }
  connectRoom (roomKey, onUpdateRoom) {
    console.log('connectRoom', roomKey);
    let roomDb = this.db.child(`rooms/${roomKey}`);
    this._room = new RoomModel(roomDb, roomKey, this._user.key);
    return this._room
      .updateOnline()
      .then(() => onUpdateRoom && this._room.setOnUpdate(onUpdateRoom));
  }
  connectUser (userKey) {
    let prevUserKey = (this._user || {}).key;
    if (userKey === prevUserKey) return Promise.resolve();
    console.log('connectUser', userKey);
    let userDb = this.db.child(`users/${userKey}`);
    this._user = new UserModel(userDb, userKey);
    return this._user.updateOnline()
      .then(() => this._user.prepareUpdateOffline())
  }
  disconnectRoom () {
    this._room && this._room.removeDbListeners();
    this._room = null;
  }
  getCard (cardKey) {
    return this.db.child(`cards/${cardKey}/_`)
      .once('value')
      .then(snap => snap.val());
  }
  async getHints (roomKey) {
    let hints = await this._getFromCardSetVersion(roomKey, `hints`);
    return _.values(hints);
  }
  async getPlayableCardSets (roomKey) {
    let [cardSets, userPacks] = await Promise.all([
      this._getFromCardSetVersion(roomKey, `cardSets`),
      this._user.getUserPacks()
    ]);
    return _.pick(cardSets, (cs) => (
      !cs.packKey || (userPacks || {})[cs.packKey]
    ));
  }
  async _getFromCardSetVersion (roomKey, path) {
    let snap = (dbPath) => this.db.child(dbPath).once('value');
    let csvKey = (await snap(`rooms/${roomKey}/cardSetVersionKey`)).val();
    let keysSnap = await snap(`cardSetVersions/${csvKey}/_/${path}`);
    let pairs = await Promise.all(
      _.keys(keysSnap.val()).map(key => snap(`${path}/${key}/_`).then(snap => [key, snap.val()]))
    );
    return _.object(pairs);
  }
  async roomExistsAndNotGameOver (roomKey) {
    let room = await this.db.child(`rooms/${roomKey}`)
      .once('value')
      .then(snap => snap.val());
    if (!room) return false;
    let roundCount = _.size(room.rounds || []);
    let isGameOver = (roundCount > 0)
      && (room.rounds[roundCount - 1].doneAt || {})[this._user.key];
    return !isGameOver;
  }
  get room () { return this._room; }
  get user () { return this._user; }
}
