module.exports.isValid = function isValid (roomCode) {
  return /^[a-zA-Z]{4}$/g.test(roomCode||'');
};

module.exports.fromKey = function fromKey (key) {
  return key && key.replace(/[^a-zA-Z]/g, '').toLowerCase().substr(-4);
};

module.exports.fromWindowLocation = function fromWindowLocation (window) {
  let p = ((window || {}).location || {}).pathname || '';
  return (p.match(/^\/game\/([a-zA-Z]{4})$/) || [0, null])[1];
};

module.exports.norm = function norm (roomCode) {
  return module.exports.fromKey(roomCode);
};

module.exports.toDisplay = function toDisplay (roomCode) {
  return roomCode && roomCode.toUpperCase();
};
