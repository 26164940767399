const _ = require('../util/underscore');

/** convert nested objects into a non-nested object with path keys */
function toPathKeyed (v, prefix, isObject) {
  let isObject0 = (isObject || _.isObject);
  if (!isObject0(v)) {
    v = (v != null) ? v : null;
    return (prefix) ? {[prefix]: v} : v;
  }
  return _.reduce(v, (res, v0, k0) => (
    _.extend(res, toPathKeyed(v0, (prefix ? `${prefix}/` : '') + k0, isObject))
  ), {});
}
module.exports.toPathKeyed = toPathKeyed;

/** given a nested obj, return the value at the path */
function getPath(obj, path) {
  return _.property(path.split('/'))(obj);
}
module.exports.getPath = getPath;

/** update a nested obj with val at path */
function setPath(obj, path, val) {
  let i = path.indexOf('/');
  if (i < 0) obj[path] = val;
  else {
    let p0 = path.slice(0, i);
    setPath(obj[p0] = obj[p0] || {}, path.slice(i + 1), val);
  }
  return obj;
}
module.exports.setPath = setPath;

/** convert a dbObj to its normal form according to its schema type */
function fromDbObj(dbObj, t) {
  let fvs = _.map(t.fields || {}, (f, fk) => [fk, getPath(dbObj, f.path)]);
  return _.object(fvs);
}
module.exports.fromDbObj = fromDbObj;

/** convert an obj to its db form according to its schema type */
function toDbObj(obj, t) {
  return _.reduce(t.fields || {},
    (res, f, fk) => setPath(res, f.path, obj[fk] || null),
    {});
}
module.exports.toDbObj = toDbObj;
