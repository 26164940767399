import { underscore as _ } from './deps'

export default class Client {
  constructor (fbApp, dbRootKey) {
    let fbFn = fbApp.functions();
    this._game = fbFn.httpsCallable(`${dbRootKey}Game`);
    ['createRoom', 'createRoomInScoreboardMode', 'joinRoom', 'noop', 'startGame'].forEach(op => {
      this[`_${op}`] = (data) => this._game( _.extend({op}, data || {}) );
    });
  }
  async createRoom () {
    return await this._createRoom()
      .then(res => _.pick(res.data, 'roomCode', 'roomKey'))
      .catch(err => {
        console.error('createRoom', err);
        throw err;
      });
  }
  async createRoomInScoreboardMode () {
    return await this._createRoomInScoreboardMode()
      .then(res => _.pick(res.data, 'roomCode', 'roomKey'))
      .catch(err => {
        console.error('createRoomInScoreboardMode', err);
        throw err;
      });
  }
  async joinRoom (roomCode) {
    return await this._joinRoom({roomCode})
      .then(res => _.pick(res.data, 'roomCode', 'roomKey'))
      .catch(err => {
        console.error('joinRoom', roomCode, err);
        throw err;
      });
  }
  async noop () {
    return await this._noop();
  }
  async startGame (roomKey, roundCount, cardSetKey) {
    return await this._startGame({roomKey, roundCount, cardSetKey})
      .then(res => _.pick(res.data, 'roundCardKeys'))
      .catch(err => {
        console.error('startGame', roomKey, roundCount, cardSetKey, err);
        throw err;
      });
  }
}
