import hostConfig from './hostConfig'
import { queryParam } from './browser'

export const authPersist = (queryParam('auth') === 's') ? 'session' :
  hostConfig('authPersist');

export const canonicalHost = 'LiesGame.com';
export const canonicalUrl = `https://${canonicalHost.toLowerCase()}`;

export const dbRootKey = hostConfig('dbRootKey');

export const feedbackSurveyUrl = 'https://docs.google.com/forms/u/0/d/1t9vMQuyJlS7QvOA-pgQ8KU85lL9prynEiKv9BXy5CFY/edit?usp=forms_home&ths=true';

console.log('config', dbRootKey, authPersist);
