import {propTypes as $p, React, underscore as _} from '../../deps';

export function Img (props) {
  let {alt, img, style} = props;
  return (
    <img src={img.src}
      style={{
        height: img.height / 2,
        width: img.width / 2,
        ...style
      }}
      {...{alt}}/>
  );
}

export function ImgAspect (props) {
  let {alt, img, style} = props;
  return (
    <div style={{
        position: 'relative',
        ...style,
        paddingTop: `${img.height / img.width * 100}%`
      }}>

      <img src={img.src}
        style={{
          bottom: 0,
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          width: '100%'
        }}
        {...{alt}}/>

    </div>
  );
}

Img.Aspect = ImgAspect;
