import * as Comp from './component';
import * as fmt from './util/fmt'
import { propTypes as $p, React, underscore as _ } from '../deps'
import Timer from './util/timer'

export default class AwaitAnswers extends React.Component {
  constructor (props) {
    super(props);
    this.state = {isExpired: false};
    _.bindAll(this, 'handleExpire');
    this.timer = new Timer({
      onExpire: this.handleExpire,
      startAt: props.answerAt,
      timeOffset: props.timeOffset,
      timeout: props.timeout
    });
  }
  componentDidMount () { this.timer.start() }
  componentWillUnmount () { this.timer.stop() }
  handleExpire () {
    this.setState({isExpired: true});
  }
  render () {
    return (
      <Comp.Main
        className='AwaitAnswers await'
        overlay={<Comp.OfflineOverlay disabled={this.props.connected} />}
      >

        { this.props.timerStartedAt && this.props.connected &&
          <Comp.Countdown
            answered
            startAt={this.props.timerStartedAt}
            timeOffset={this.props.timeOffset}
            timeout={this.props.timeout}
          />
        }

        <Comp.Header/>

        <h1>
          Waiting for{' '}
          {fmt.plural(_.size(this.props.playersLeft), 'a lie', 'lies')}{' '}
          from{' '}
          <Comp.PlayerSeries players={this.props.playersLeft}/>
        </h1>
        
        { !this.props.timerStartedAt &&
          (_.size(this.props.playersLeft) / _.size(this.props.players) <= 0.5) &&
          <button
            type="button"
            style={{
              marginBottom: 30,
              marginTop: 30,
            }}
            onClick={this.props.onTimerStart}
          >
            Start Meaningless Timer
          </button>
        }

        {(!this.state.isExpired) ? null : (
          /* TODO */ null
        )}

      </Comp.Main>
    );
  }
}
AwaitAnswers.displayName = `AwaitAnswers`;
