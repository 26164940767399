import { window as w } from '../../runtime'

export function pushRoom (roomCode) {
  let q = w.location.search;
  w.history.pushState({roomCode}, '', `/game/${roomCode}${q}`);
}

export function pushRoot () {
  let q = w.location.search;
  w.history.pushState(null, '', `/${q}`);
}

/** @param e optional popstate event */
export function getRoomCode (e) {
  return ((e || w.history || {}).state || {}).roomCode;
}
