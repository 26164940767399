import firebase from '../../firebase'

const NOW = firebase.database.ServerValue.TIMESTAMP;

export class RoundModel {
  constructor (roundDb, roundIndex, userKey) {
    this.roundDb = roundDb;
    this._roundIndex = roundIndex;
    this._userKey = userKey;
  }
  determineTypoRound () {
    let randomIndex = Math.floor((Math.random() * 100));
    if (randomIndex < 18) { // typos occur 18% of the time
      return this.roundDb.update({
        [`typoRound`]: true
      });
    } else {
      return this.roundDb.update({
        [`typoRound`]: false
      });
    }
  }
  answerStartTimer () {
    return this.roundDb.update({
      [`answerTimerStartedAt`]: NOW
    });
  }
  voteStartTimer () {
    return this.roundDb.update({
      [`voteTimerStartedAt`]: NOW
    });
  }
  doneAnnounceAnswers () {
    return this.roundDb.update({
      [`announcedAnswers`]: true,
      [`voteAt`]: NOW
    });
  }
  doneAnnounceVote (voteInfoIndex) {
    return this.roundDb.update({
      [`announcedVotes`]: (voteInfoIndex + 1),
      [`announcedVotesAt`]: NOW
    });
  }
  doneRoundEnd () {
    return this.roundDb.update({[`doneAt/${this._userKey}`]: NOW});
  }
  doneRoundEndScoreboardPlayer(scoreboardPlayerKey) {
    return this.roundDb.update({[`doneAt/${scoreboardPlayerKey}`]: NOW});
  }
  submitAnswer (answer) {
    // also set voteAt here, since there's no announce during 2 player games
    return this.roundDb.update({
      [`answers/${this._userKey}`]: answer,
      [`answerEndAt/${this._userKey}`]: NOW,
      [`voteAt`]: NOW
    });
  }
  submitVote (vote) {
    return this.roundDb.update({
      [`votes/${this._userKey}`]: vote,
      [`voteEndAt/${this._userKey}`]: NOW
    });
  }
  get index () { return this._roundIndex; }
}
