import Loading from './Loading'
import SelectRoom from './SelectRoom'
import withConnected from './withConnected'
import withGameController from './withGameController'
import withRoomController from './withRoomController'
import withTimeOffset from './withTimeOffset'

import { authPersist, dbRootKey } from '../util/runtimeConfig'
import Client from '../client'
import { ConnectedModel } from './model/connectedModel'
import { createModel } from './model'
import firebase from '../firebase'
import * as img from './img'
import { React, underscore as _ } from '../deps'
import { TimeOffsetModel } from './model/timeOffsetModel'

import './App.css'

const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence[(authPersist === 'session' ? 'SESSION' : 'LOCAL')])
  .catch(err => console.error(`auth.setPersistence`, authPersist, err));

const database = firebase.database();
const client = new Client(firebase, dbRootKey);
const model = createModel(dbRootKey);
const connectedModel = new ConnectedModel(database.ref());
const timeOffsetModel = new TimeOffsetModel(database.ref());

const Game = withGameController(client, model);
const Room = withRoomController(auth, client, model, Loading, SelectRoom, Game);
const AppBase = withTimeOffset(timeOffsetModel)(
  withConnected(connectedModel)(
    Room
  )
);

const App = (props) => {
  return <AppBase/>;
};

App.displayName = `App`;

export default App;

// preload images
_.map(img, ({src}) => { if (src) new Image().src = src; });
_.map(img.avatars, ({src}) => { if (src) new Image().src = src; });
