import App from './App'
import { React, ReactDOM } from '../deps'
import * as rt from '../runtime'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-162991085-1', {
  titleCase: false
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<App/>, rt.document.getElementById('app'));
