import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'

import * as rt from './runtime'

rt.window.firebase = rt.window.firebase || firebase;

firebase.initializeApp({
  apiKey: "AIzaSyAg9jt0OsDMzw8LfC0o4qLevdmjGQjP9d4",
  authDomain: "believable-lies.firebaseapp.com",
  databaseURL: "https://believable-lies.firebaseio.com",
  projectId: "believable-lies",
  storageBucket: "believable-lies.appspot.com",
  messagingSenderId: "1018951702394"
});

export default firebase;
