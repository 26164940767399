import React from 'react';
import {closebutton} from '../img';

export function TypoTip(props) {
  return (
    <div className="typo-tip">
      <button
        className='typo-tip-close'
        onClick={props.onClose}>
        <img
          src={closebutton.src}
          alt="close typo tooltip"
          style={{
            height: closebutton.height / 2,
            width: closebutton.width / 2
          }}
        />
      </button>
      <div role="alert">
        <span className="typo-tip-header">Careful!</span>
        <p>Sometimes the correct answers have intentional typos & grammatical mistakes.</p>
      </div>
    </div>
  );
}

TypoTip.displayName = 'TypoTip';