import * as Comp from './component';
import * as fmt from './util/fmt'
import { propTypes as $p, React, underscore as _ } from '../deps';

export default class EnterAnswer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      answer: '',
      error: null,
      info: null,
      showHints: false
    };
    _.bindAll(this, 'handleChange', 'handleClickHideHints',
      'handleClickShowHints', 'handleSubmit');
  }
  handleChange (e) {
    this.setState({answer: e.target.value});
    if (e.target.value.length >= 260) {
      this.setState({
        info: `280 characters max - ${280 - e.target.value.length} remaining`
      });
    } else {
      this.setState({
        info: null,
      });
    }
  }
  handleSubmit (e) {
    e.preventDefault();
    if (this.state.answer === '') {
      this.setState({error: 'You must enter a lie'});
    } else {
      this.props.onSubmitAnswer(this.state.answer);
    }
    if (this.props.typoRound === null || this.props.typoRound === undefined) {
      this.props.determineTypoRound();
    }
  }
  handleClickHideHints (e) {
    this.setState({showHints: false});
  }
  handleClickShowHints (e) {
    this.setState({showHints: true});
  }
  render () {
    let {props: {roomCreatedAt}} = this;
    return (
      <Comp.Main
        className='EnterAnswer'
        overlay={this.props.connected ?
          <Comp.Dialog
            disabled={!this.state.showHints}
            onClose={this.handleClickHideHints}>

            <h1 style={{marginBottom: 48}}>
              Example Lies
            </h1>

            {this.props.hints.map((h, i) => (
              <p key={i}
                style={{marginBottom: 32}}>
                {fmt.char.ldquo}
                {h.fact}
                {fmt.char.rdquo}
              </p>
            ))}

          </Comp.Dialog>
          : <Comp.OfflineOverlay disabled={this.props.connected} />
        }>

        { this.props.timerStartedAt && this.props.connected && !this.state.showHints &&
          <Comp.Countdown
            answering
            startAt={this.props.timerStartedAt}
            timeOffset={this.props.timeOffset}
            timeout={this.props.timeout}
          />
        }
        <Comp.Header />

        {(this.props.announcerPlayerKey === this.props.selfPlayerKey) ? (
          <React.Fragment>

            <h1 style={{marginBottom: 8}}>
              Topic: {this.props.card.topic}
            </h1>

            <div className='flex'
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 12
              }}>

              <Comp.Avatar
                playerIndex={this.props.players[this.props.selfPlayerKey].index}
                style={{marginBottom: 24}}
                {...{roomCreatedAt}}
              />

              <p style={{
                  flexBasis: 'auto',
                  marginLeft: 12,
                  textAlign: 'left'
                }}>

                You must announce this round{`'`}s topic to the other players.{' '}
                Then write a believable lie about that topic.

              </p>

            </div>

          </React.Fragment>
        ) : (
          <React.Fragment>

            <h1>
              Ask <Comp.Player player={this.props.players[this.props.announcerPlayerKey]}/>{' '}
              for this round{`'`}s topic
            </h1>

            <p style={{marginBottom: 24}}>
              Then write a believable lie about it.
            </p>

          </React.Fragment>
        )}

        <form onSubmit={this.handleSubmit}
          style={{marginBottom: 24}}>

          <div className='flex'
            style={{marginBottom: 32}}>

            <textarea
              className={Comp.errorClass(this)}
              onChange={this.handleChange}
              style={{marginBottom: 4}}
              value={this.state.answer}
              maxLength={280}
            />

            <Comp.ErrorInfo error={this.state.error}/>
            {this.state.info &&
              <span style={{textAlign: 'center'}}>{this.state.info}</span>
            }

          </div>

          <input type="submit" value="Done"/>

        </form>

        <button className='text'
          onClick={this.handleClickShowHints}>

          See example lies {'>'}

        </button>

      </Comp.Main>
    );
  }
}
EnterAnswer.displayName = `EnterAnswer`;
