import {React} from './deps'

export class Component extends React.Component {
  /** make setState() return a Promise */
  setState (updater, cb) {
    return new Promise(resolve => super.setState(updater, () => {
      cb && cb();
      resolve();
    }));
  }
}
