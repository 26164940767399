import React from 'react';
import { Dialog } from './Dialog'

export function OfflineOverlay(props) {
  return (
    <Dialog
      disabled={props.disabled}
      onClose={() => location.reload(true)}
      role="alert"
    >
      <h1>Internet Connection Lost</h1>
      <p>Please reconnect and try again.</p>
      <br />
      <button
        type='button'
        style={{
          marginBottom: 30,
          marginTop: 30,
        }}
        onClick={() => location.reload(true)}
      >
        Retry
      </button>

    </Dialog>
  );
}

OfflineOverlay.displayName = 'OfflineOverlay';
