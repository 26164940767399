import * as Comp from './component'
import { propTypes as $p, React, underscore as _ } from '../deps'
import { factVoteKey } from './util/app'
import * as fmt from './util/fmt'
import { point0Big, point0Small, point1Big, point1Small, ribbonSmall} from './img';

export default class RoundEnd extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showTypoTip: false,
    }
    _.bindAll(this, 'handleClickDone', 'handleCloseTypoTip');
  }
  componentDidMount() {
    const { typoRound } = this.props;
    if (typoRound) {
      this.setState({
        showTypoTip: true,
      });
    }
  }
  handleClickDone (e) {
    this.props.onDone();
  }
  handleCloseTypoTip () {
    this.setState({
      showTypoTip: false,
    });
  }
  render () {
    let selfPlayerKey = this.props.selfPlayerKey;
    let players = this.props.players;
    let roundIndex = this.props.roundIndex;
    let round = this.props.room.rounds[roundIndex];
    let votes = round.votes;

    let liePlayerPairs = _.pairs(votes)
      .filter(([pk, vk]) => vk === selfPlayerKey)
      .map(([pk, vk]) => players[pk]);

    let selfVoteKey = votes[selfPlayerKey];

    const total = (s) => ((s.truth || 0) + (s.lie || 0));
    const liesOnly = (s) => (s.lie || 0);
    const add = (v, prop) => (v[prop] = (v[prop] || 0) + 1);

    let playerScores = _.range(roundIndex + 1)
      .reduce((res, i) => (
        _.reduce(this.props.room.rounds[i].votes, (res0, vk, pk) => {
          (vk === factVoteKey) ? add(res0[pk], 'truth') : add(res0[vk], 'lie');
          return res0;
        }, res)
      ), _.mapObject(players, () => ({})));

    let sortedScorePairs = _.chain(playerScores)
      .pairs()
      .sortBy(([pk, p]) => -total(p))
      .value();
    let maxTotalScore = total(sortedScorePairs[0][1]);
    let winnerScorePairs = sortedScorePairs.filter(([pk, ps]) => total(ps) === maxTotalScore);
/*
    let scoreRows = sortedScorePairs.map(([pk, p]) => (
      <tr key={pk}>
        <td><Comp.Player player={p}/></td>
        <td>
          {_.range(p.lie || 0).map(i => <span key={i} className="point-lie">{config.pointChar.lie}</span>)}
          {_.range(p.truth || 0).map(i => <span key={i} className="point-truth">{config.pointChar.truth}</span>)}
        </td>
      </tr>
    ));

    let scoreTable = <table><tbody>{scoreRows}</tbody></table>;

    let sortedLieScorePairs = _.chain(playerScores)
      .pairs()
      .sortBy(([pk, p]) => -liesOnly(p))
      .value();
    let maxTotalLieScore = total(sortedLieScorePairs[0][1]);
    let winnerLieScorePairs = sortedLieScorePairs.filter(([pk, p]) => liesOnly(p) === maxTotalLieScore);

    let winnerLieInfo = (winnerLieScorePairs.length !== 1) ? null :
      (<h2 className="winner"><Comp.Player player={winnerLieScorePairs[0][1]}/> IS THE BEST LIAR</h2>);

    let scoreInfo = (this.props.isGameOver) ?
      (<React.Fragment>
          <h2>FINAL SCORES</h2>
          {scoreTable}
          <h2 className="winner"><Comp.PlayerSeries players={winnerScorePairs.map(([pk, p]) => p)}/> {fmt.plural(winnerScorePairs.length, 'IS THE WINNER', 'ARE THE WINNERS')}!</h2>
          {winnerLieInfo}
        </React.Fragment>) :
      (<React.Fragment>
          <h2>SCORES</h2>
          {scoreTable}
        </React.Fragment>);
*/
    return (
      <Comp.Main
        className='RoundEnd'
        overlay={<Comp.OfflineOverlay disabled={this.props.connected} />}
      >

        <Comp.Header style={{marginBottom: 32}}/>

        {(!this.props.isGameOver) ? null : (
          <React.Fragment>

            <h1>
              Final Scores
            </h1>

            <ul
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: 48,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                zIndex: (this.props.connected ? 0 : -1)
              }}>

              {sortedScorePairs.map(([pk, ps], i) => (
                <li key={pk}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    textAlign: 'center',
                    width: '50%'
                  }}>

                  <div className='flex'
                    style={{
                      position: 'relative'
                    }}>

                    <Comp.Avatar playerIndex={players[pk].index}
                      style={{
                        alignSelf: 'center',
                        marginBottom: 8
                      }}
                      roomCreatedAt={this.props.roomCreatedAt}/>

                    <Comp.Img.Aspect 
                      img={ribbonSmall}
                      alt=""
                      style={{
                        bottom: 0,
                        display: (maxTotalScore === total(ps)) ? 'block' : 'none',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        width: '100%'
                      }}
                    />

                    <div className='noselect'
                      style={{
                        bottom: 0,
                        color: '#E5CF96',
                        display: (maxTotalScore === total(ps)) ? 'block' : 'none',
                        fontSize: 15,
                        left: 0,
                        paddingBottom: 7,
                        position: 'absolute',
                        right: 0
                      }}>
                      Winner
                    </div>

                  </div>

                  <div>

                    <Comp.Player player={players[pk]}/>

                    <div>
                      {fmt.num((ps.lie || 0) + (ps.truth || 0))}
                      pts
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: 4
                      }}>

                      {_.range(ps.lie || 0).map(i => (
                        <img
                          key={i}
                          src={point0Small.src}
                          alt="lie point"
                          style={{
                            height: point0Small.height / 2,
                            width: point0Small.width / 2
                          }}
                        />
                      ))}

                      {_.range(ps.truth || 0).map(i => (
                        <img
                          key={i}
                          src={point1Small.src}
                          alt="truth point"
                          style={{
                            height: point1Small.height / 2,
                            width: point1Small.width / 2
                          }}
                        />
                      ))}
                    </div>

                  </div>

                </li>
              ))}

            </ul>

            <Comp.Hr style={{marginBottom: 32, marginTop: 0}}/>

          </React.Fragment>
        )}

        <h1>
          The Truth
        </h1>

        <Comp.Answer
          index={this.props.factVoteIndex}
          label={this.props.typoRound && this.props.card.typo
          ? this.props.card.typo
          : this.props.card.fact
          }
        />

        {(!this.props.card.bonus) ? null : (
          <Comp.Answer.Bonus>
            {this.props.card.bonus}
          </Comp.Answer.Bonus>
        )}

        <Comp.Hr style={{marginBottom: 32, marginTop: 24}}/>

        {(liePlayerPairs.length > 0) ? (
          <PointRow pointLabel={fmt.num(liePlayerPairs.length)}
            pointImg={point0Big}>

            <Comp.PlayerSeries players={liePlayerPairs}/>{' '}
            guessed your lie

          </PointRow>
        ) : (
          <PointRow
            pointImg={point0Big}
            pointStyle={{
              opacity: 0.333,
              zIndex: -1,
            }}
          >
            Nobody guessed your lie
          </PointRow>
        )}

        {(selfVoteKey === factVoteKey) ? (
          <PointRow pointLabel={fmt.num(1)}
            pointImg={point1Big}>

            You correctly identified the truth

          </PointRow>
        ) : (
          <PointRow
            pointImg={point1Big}
            pointStyle={{
              opacity: 0.333,
              zIndex: -1,
            }}
          >
            You guessed{' '}
            <Comp.Player player={players[selfVoteKey]}/>
            {`'`}s lie
          </PointRow>
        )}

        {(this.props.isGameOver) ? null : (
          <React.Fragment>

            <Comp.Hr style={{marginBottom: 24, marginTop: 16}}/>

            <h1>Scores</h1>

            <ul
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: 48,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0
              }}>

              {sortedScorePairs.map(([pk, ps], i) => (
                <li key={pk}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    textAlign: 'center',
                    width: '50%'
                  }}>

                  <Comp.Avatar playerIndex={players[pk].index}
                    style={{
                      alignSelf: 'center',
                      marginBottom: 8
                    }}
                    roomCreatedAt={this.props.roomCreatedAt}/>

                  <div>

                    <Comp.Player player={players[pk]}/>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: 4
                      }}>

                      {_.range(ps.lie || 0).map(i => (
                        <img
                          key={i}
                          src={point0Small.src}
                          alt="lie point"
                          style={{
                            height: point0Small.height / 2,
                            width: point0Small.width / 2
                          }}
                        />
                      ))}

                      {_.range(ps.truth || 0).map(i => (
                        <img
                          key={i}
                          src={point1Small.src}
                          alt="truth point"
                          style={{
                            height: point1Small.height / 2,
                            width: point1Small.width / 2
                          }}/>
                      ))}
                    </div>

                  </div>

                </li>
              ))}

            </ul>

          </React.Fragment>
        )}

        {(!(round.doneAt || {})[selfPlayerKey]) ? (
          <input style={{marginTop: 32, marginBottom: 96}}
            type="button"
            value={this.props.isGameOver ? 'New game' : 'Got it'}
            onClick={this.handleClickDone}/>
        ) : (
          <p style={{marginTop: 32, marginBottom: 96}}>
            Waiting for{' '}
            <Comp.PlayerSeries players={this.props.playersLeft}/>
          </p>
        )}

        {this.props.typoRound && this.state.showTypoTip && this.props.connected &&
          <Comp.TypoTip onClose={this.handleCloseTypoTip} />
        }

      </Comp.Main>
    );
  }
}
RoundEnd.displayName = `RoundEnd`;

export function PointRow (props) {
  let {children, pointImg, pointLabel, pointStyle, pointLabelStyle, style} = props;
  return (
    <div className='flex'
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 24,
        ...style
      }}>

      <div className='flex'
        style={{
          alignItems: 'center',
          background: (!pointImg.src) ? null : `url('${pointImg.src}') center / ${pointImg.width / 2}px ${pointImg.height / 2}px no-repeat`,
          display: 'flex',
          height: pointImg.height / 2,
          justifyContent: 'center',
          marginRight: 8,
          marginLeft: 8,
          margin: '0px 8px auto',
          width: pointImg.width / 2,
          ...pointStyle
        }}>

        <div className='noselect'
          style={{
            color: '#E5CF96',
            fontSize: 17,
            ...pointLabelStyle
          }}>

          {(!pointLabel) ? null : '+'}
          {pointLabel}

        </div>

      </div>

      <div className='flex'
        style={{
          alignItems: 'center',
          alignSelf: 'stretch',
          display: 'flex',
          flex: 1,
          flexDirection: 'row'
        }}>

        <div>
          {children}
        </div>

      </div>

    </div>
  );
}
