import {avatars} from '../img';
import { propTypes as $p, React, underscore as _ } from '../../deps'
import * as fmt from '../util/fmt'

const max = {
  h: avatars.reduce((res, {h}) => Math.max(res, h), 0),
  w: avatars.reduce((res, {w}) => Math.max(res, w), 0)
};

const ordAvatars = [
  <span>1<sup>st</sup></span>,
  <span>2<sup>nd</sup></span>,
  <span>3<sup>rd</sup></span>,
  <span>4<sup>th</sup></span>,
  <span>5<sup>th</sup></span>,
  <span>6<sup>th</sup></span>,
  <span>7<sup>th</sup></span>,
  <span>8<sup>th</sup></span>,
  <span>9<sup>th</sup></span>,
  <span>10<sup>th</sup></span>,
  <span>11<sup>th</sup></span>,
  <span>12<sup>th</sup></span>,
  <span>13<sup>th</sup></span>,
  <span>14<sup>th</sup></span>,
  <span>15<sup>th</sup></span>,
  <span>16<sup>th</sup></span>
];

export function Avatar (props) {
  let {playerIndex, roomCreatedAt, scale, style, imgStyle} = props;
  playerIndex = playerIndex || 0;
  let avatar = avatars[(roomCreatedAt + playerIndex) % avatars.length];
  scale = (scale == null) ? 1 : scale;
  return (
    <div className='flex'
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: (max.h / 3 * scale),
        width: (max.w / 3 * scale),
        ...style,
      }}>

      <img
        alt={avatar.alt || "avatar"}
        src={avatar.src}
        style={{
          display: 'block',
          width: (avatar.w / 3 * scale),
          ...imgStyle
        }}/>

    </div>
  );
}
Avatar.displayName = `Avatar`;

export function Player (props) {
  let {children, player, style, ...thruProps} = props;
  return (
    <span className="player"
      style={{
        wordBreak: 'break-word',
        ...style,
      }}
      {...thruProps}>

      {player.name}

    </span>
  );
}
Player.displayName = `Player`;

export function PlayerSeries (props) {
  let players = fmt.series(
    _.map(props.players, p => <Player player={p}/>)
  );
  return React.createElement(
    React.Fragment,
    null,
    ...players
  );
}
PlayerSeries.displayName = `PlayerSeries`;

export function AvatarList (props) {
  let {children, players, roomCreatedAt, style, avatarStyle, imgStyle, playerStyle, scale, ...thruProps} = props;
  let sortedPlayers = _.chain(players)
    .pairs()
    .sortBy(([k, {joinAt}]) => joinAt)
    .value();
  return (
    <ul {...thruProps}
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        ...style
      }}>

      {sortedPlayers.map(([key, player], i) => (
        <li {...{key}}
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            textAlign: 'center',
            width: '50%'
          }}>

          <Avatar
            playerIndex={player.index}
            scale={scale}
            imgStyle={imgStyle}
            style={{
              alignSelf: 'center',
              marginBottom: 8,
              ...avatarStyle
            }}
            {...{roomCreatedAt}}/>

          {(player.name) ? <Player style={playerStyle} {...{player}}/> : (
            <span className="unnamed-player" style={playerStyle}>
              Waiting for {ordAvatars[player.index]} player to pick a name…
            </span>
          )}

        </li>
      ))}

    </ul>
  );
}
AvatarList.displayName = `AvatarList`;
