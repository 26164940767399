import AnnounceAnswers from './AnnounceAnswers'
import AnnounceVote from './AnnounceVote'
import AwaitAnswers from './AwaitAnswers'
import AwaitPlayers from './AwaitPlayers'
import AwaitVotes from './AwaitVotes'
import EnterAnswer from './EnterAnswer'
import EnterName from './EnterName'
import EnterVote from './EnterVote'
import RoundEnd from './RoundEnd'
import Scoreboard from './Scoreboard'
import SelectDeck from './SelectDeck'

import config from './util/config'
import { Component } from '../component'
import { factVoteKey } from './util/app'
import { React, underscore as _ } from '../deps'
import { invertGrouped, shuffleSeeded, sortedPairs } from './util'

export default function withGameController (client, model) {
  class GameController extends Component {
    constructor (props) {
      super(props)
      this.state = {
        cardSets: null,
        hints: null,
        room: null,
        card: null,
        roundIndex: null,
      };
      _.bindAll(this,
        'handleDetermineTypoRound',
        'handleAnswerTimerStart',
        'handleVoteTimerStart',
        'handleDoneAnnounceAnswers',
        'handleDoneAnnounceVote',
        'handleDoneRoundEnd',
        'handleSubmitAnswer',
        'handleSubmitName',
        'handleSubmitVote',
        'handleUpdateRoom',
        'handleAllPlayersPresent'
      );
    }
    componentDidMount () {
      return this.load(this.props.roomKey)
        .then(() => model.room.setOnUpdate(this.handleUpdateRoom))
        .catch(err => console.error(err));
    }
    componentWillUnmount () {
      model.room && model.room.removeDbListeners();
    }
    async load (roomKey) {
      let [cardSets, hints] = await Promise.all([
        model.getPlayableCardSets(roomKey),
        model.getHints(roomKey)
      ]);
      await this.setState({cardSets, hints});
    }
    handleDetermineTypoRound () {
      return model.room.round.determineTypoRound()
        .catch(err => console.error('handleDetermineTypoRound', err));
    }
    handleAnswerTimerStart (e) {
      e.preventDefault();
      return model.room.round.answerStartTimer()
        .catch(err => console.error('answerStartTimer', err));
    }
    handleVoteTimerStart (e) {
      e.preventDefault();
      return model.room.round.voteStartTimer()
        .catch(err => console.error('voteStartTimer', err));
    }
    handleDoneAnnounceAnswers () {
      return model.room.round.doneAnnounceAnswers()
        .catch(err => console.error('handleDoneAnnounceAnswers', err));
    }
    handleDoneAnnounceVote (voteInfoIndex) {
      return model.room.round.doneAnnounceVote(voteInfoIndex)
        .catch(err => console.error('handleDoneAnnounceVote', voteInfoIndex, err));
    }
    handleUpdateRoom (room, roundIndex) {
      let newState = {room, roundIndex};
      let oldRoundIndex = (this.state.card || {}).index;
      if (roundIndex != null && oldRoundIndex !== roundIndex) {
        let cardKey = room.rounds[roundIndex].cardKey;
        return model.getCard(cardKey)
          .then(card => {
            card.index = roundIndex;
            newState.card = card;
            return this.setState(newState);
          })
          .catch(err => console.error('handleUpdateRoom newRound', room, roundIndex, err));
      }
      else {
        return this.setState(newState)
          .catch(err => console.error('handleUpdateRoom', room, roundIndex, err));
      }
    }
    handleAllPlayersPresent () {
      return model.room.submitAllPlayersPresent()
        .catch(err => console.error('handleAllPlayersPresent', err));
    }
    handleStartGame (cardSetKey) {
      return client.startGame(model.room.key, config.roundCount, cardSetKey)
        .catch(err => console.error('handleStartGame', cardSetKey, err));
    }
    handleSubmitAnswer (answer) {
      return model.room.round.submitAnswer(answer)
        .catch(err => console.error('handleSubmitAnswer', answer, err));
    }
    handleSubmitName (name) {
      return model.room.submitName(name)
        .catch(err => console.error('handleSubmitName', name, err));
    }
    handleSubmitVote (vote) {
      return model.room.round.submitVote(vote)
        .catch(err => console.error('handleSubmitVote', vote, err));
    }
    handleDoneRoundEnd () {
      let s = this.state;
      let scoreboardPlayerKey = (s.room || {}).scoreboardPlayerKey;
      let doneAt = ((s.room || {}).rounds[s.roundIndex] || {}).doneAt;
      let scoreboardPlayerKeyDoneAt = scoreboardPlayerKey && doneAt && ((s.room || {}).rounds[s.roundIndex]).doneAt[scoreboardPlayerKey];
      let isGameOver = !s.room.rounds[s.roundIndex + 1];
      if (!scoreboardPlayerKey) {
        if (isGameOver) {
          let roomModel = model.room;
          return this.props.onGameEnd()
            .then(() => roomModel.doneGameEnd())
            .catch(err => console.error('handleDoneRoundEnd gameOver', err));
        }
        else return model.room.doneRoundEnd()
          .catch(err => console.error('handleDoneRoundEnd', err));
      } else {
        if (isGameOver) {
          let roomModel = model.room;
          return this.props.onGameEnd()
            .then(() => roomModel.doneGameEnd())
            .catch(err => console.error('handleDoneRoundEnd gameOver scoreboardPlayerKey', err));
        }
        else return model.room.doneRoundEnd()
          .then(() => !scoreboardPlayerKeyDoneAt && model.room.doneRoundEndScoreboardPlayer(scoreboardPlayerKey))
          .catch(err => console.error('handleDoneRoundEnd scoreboardPlayerKey', err));
      }
    }
    render () {
      let {connected, getTimeOffset, roomCode} = this.props;
      let {card, cardSets, hints, room, roundIndex} = this.state;

      let scoreboardPlayerKey = (room || {}).scoreboardPlayerKey;
      let leaderPlayerKey = (room || {}).hostPlayerKey;
      let players = (room || {}).players;
      let round = ((room || {}).rounds || [])[roundIndex || 0] || {};
      let roomCreatedAt = (room || {}).createdAt || 0;
      let roundSeed = ((room || {}).createdAt || 0) + (roundIndex || 0);
      let allPlayersPresentAt = (room || {}).allPlayersPresentAt;
      let selfPlayerKey = model.user.key;
      let timeOffset = getTimeOffset();

      const { [scoreboardPlayerKey]: value, ...activePlayers } = players || {};

      let playerKeys = _.chain(activePlayers || {})
        .pairs()
        .sortBy(([k, {joinAt}]) => joinAt)
        .map(([k]) => k)
        .value();

      playerKeys.forEach((pk, i) => {
        activePlayers[pk].index = i;
      });

      let announcerPlayerKey = playerKeys[(playerKeys.indexOf(leaderPlayerKey) + roundIndex) % playerKeys.length];
      let selfPlayerIndex = playerKeys.indexOf(selfPlayerKey);

      let answers = _.assign({}, round.answers, {[factVoteKey]: (card || {}).fact});
      let sortedAnswerPairs = sortedPairs(answers, (v, k) => k);
      let answerPairs = shuffleSeeded(sortedAnswerPairs, roundSeed);

      let allVoteInfos = invertGrouped(round.votes || {});
      let factVoteInfo = allVoteInfos[factVoteKey] || [];
      let voteInfoPairs = sortedPairs(allVoteInfos, pks => pks.length)
        .filter(([v, pks]) => v !== factVoteKey)
        .concat([[factVoteKey, factVoteInfo]]);
      let voteAnswerIndexes = voteInfoPairs.map(([k]) => (
        answerPairs.findIndex(([ak]) => k === ak)
      ));
      let voteInfoIndex = (round.announcedVotes || 0);

      let selfDone = (obj) => !!(obj || {})[selfPlayerKey];
      let playersLeft = (obj) => _.omit(activePlayers, _.keys(obj || {}));

      let answerAt = round.answerAt;
      let answerTimerStartedAt = round.answerTimerStartedAt;
      let voteTimerStartedAt = round.voteTimerStartedAt;
      let typoRound = round.typoRound;
      let selfAnswered = selfDone(round.answers);
      let answersLeft = playersLeft(round.answers);
      let selfVoted = selfDone(round.votes);
      let votesLeft = playersLeft(round.votes);

      let cardSetName = (((cardSets || {})[(room || {}).cardSetKey] || {}).name) || null;

      if (!players) return null;
      else if (scoreboardPlayerKey === selfPlayerKey) {
        return <Scoreboard
          onDone={this.handleDoneRoundEnd}
          answerTimeout={config.timeout.answer}
          voteTimeout={config.timeout.vote}
          voteAnswerIndex={voteAnswerIndexes[voteInfoIndex]}
          voteInfoPair={voteInfoPairs[voteInfoIndex]}
          {...
            {
              announcerPlayerKey,
              answerPairs,
              answersLeft,
              answers,
              answerTimerStartedAt,
              card,
              connected,
              leaderPlayerKey,
              players: activePlayers,
              room,
              roomCode,
              roomCreatedAt,
              roundIndex,
              roundSeed,
              selfPlayerIndex,
              selfPlayerKey,
              timeOffset,
              typoRound,
              voteInfoPairs,
              votesLeft,
              voteTimerStartedAt
            }
          }
        />;
      } 
      else if (!players[selfPlayerKey].name) {
        return <EnterName
          onSubmitName={this.handleSubmitName}
          {...{connected, leaderPlayerKey, roomCode, roomCreatedAt, roundSeed, selfPlayerIndex, selfPlayerKey}}/>;
      }
      else if (!answerAt && !allPlayersPresentAt) {
        return <AwaitPlayers
          onClickStart={this.handleAllPlayersPresent}
          {...{cardSets, connected, leaderPlayerKey, players: activePlayers, roomCode, roomCreatedAt, roundSeed, selfPlayerKey}}/>;
      }
      else if (!answerAt && allPlayersPresentAt) {
        return <SelectDeck
          onStartGame={this.handleStartGame}
          {...{cardSets, connected, leaderPlayerKey, players: activePlayers, roomCode, roundSeed, selfPlayerKey}}/>;
      }
      else if (!selfAnswered) {
        let hintIndex = ((roundSeed + selfPlayerIndex) % Math.floor(hints.length / config.hintCount)) * config.hintCount;
        let playerHints = (hints || []).slice(hintIndex, hintIndex + config.hintCount);

        return <EnterAnswer
          hints={playerHints}
          timeout={config.timeout.answer}
          onSubmitAnswer={this.handleSubmitAnswer}
          determineTypoRound={this.handleDetermineTypoRound}
          timerStartedAt={answerTimerStartedAt}
          {...{announcerPlayerKey, answerAt, card, connected, players: activePlayers, roomCreatedAt, roundSeed,
            selfPlayerKey, timeOffset, typoRound}}/>;
      }
      else if (_.size(answersLeft) > 0) {
        return <AwaitAnswers
          playersLeft={answersLeft}
          timeout={config.timeout.answer}
          onTimerStart={this.handleAnswerTimerStart}
          timerStartedAt={answerTimerStartedAt}
          {...{answerAt, connected, players: activePlayers, timeOffset}}/>;
      }
      else if (!round.announcedAnswers) {
        return <AnnounceAnswers
          onDone={this.handleDoneAnnounceAnswers}
          {...{answerPairs, announcerPlayerKey, card, connected, players: activePlayers, selfPlayerKey, typoRound}}/>;
      }
      else if (!selfVoted) {
        let voteAt = round.voteAt;

        return <EnterVote
          timeout={config.timeout.vote}
          onSubmitVote={this.handleSubmitVote}
          timerStartedAt={voteTimerStartedAt}
          {...{answerPairs, card, connected, selfPlayerKey, timeOffset, typoRound, voteAt}}/>;
      }
      else if (_.size(votesLeft) > 0) {
        let voteAt = round.voteAt;

        return <AwaitVotes
          playersLeft={votesLeft}
          timeout={config.timeout.vote}
          onTimerStart={this.handleVoteTimerStart}
          timerStartedAt={voteTimerStartedAt}
          {...{connected, players: activePlayers, timeOffset, voteAt}}/>;
      }
      else if (voteInfoIndex < voteInfoPairs.length) {
        let voteDoneCallback = this.handleDoneAnnounceVote.bind(this, voteInfoIndex);
        let isGameOver = !room.rounds[roundIndex + 1];

        return <AnnounceVote
          voteAnswerIndex={voteAnswerIndexes[voteInfoIndex]}
          voteInfoPair={voteInfoPairs[voteInfoIndex]}
          onDone={voteDoneCallback}
          {...{announcerPlayerKey, answers, card, cardSetName, connected, isGameOver, players: activePlayers, selfPlayerKey, typoRound}}/>;
      } else {
      // else if (_.size(playersLeft(round.doneAt)) > 0) {
        let isGameOver = !room.rounds[roundIndex + 1];
        let donesLeft = playersLeft(round.doneAt);
        let factVoteIndex = answerPairs.findIndex(([ak]) => factVoteKey === ak);

        return <RoundEnd
          playersLeft={donesLeft}
          onDone={this.handleDoneRoundEnd}
          {...{card, connected, factVoteIndex, isGameOver, players: activePlayers, room, roomCreatedAt, roundIndex, roundSeed, selfPlayerKey, typoRound}}/>;
      }
      /*
      let game = (main) ?
        <main {...{className}}>{main}</main> :
        null;
      return (
        <Page>
          <AppBase
            onReset={this.handleReset}
            onConnectRoom={this.handleConnectRoom}
            onUpdateRoom={this.handleUpdateRoom}
            onStartGame={this.handleStartGame}>

            {game}

          </AppBase>
        </Page>
      );
      */
    }
  }
  GameController.displayName = `GameController`;
  return GameController;
}
