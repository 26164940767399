//import propTypes from 'prop-types/prop-types.min'
import propTypes from 'prop-types'

//import React from 'react/umd/react.development.js'
//import React from 'react/cjs/react.production.min'
import React from 'react'
//import ReactDOM from 'react-dom/umd/react-dom.development.js'
//import ReactDOM from 'react-dom/cjs/react-dom.production.min'
import ReactDOM from 'react-dom'

import * as underscore from './util/underscore'

export { propTypes, React, ReactDOM, underscore }
