import firebase from '../../firebase'
import { underscore as _ } from '../../deps'

export function createTimeOffsetModel () {
  let db = firebase.database().ref();
  return new TimeOffsetModel(db);
}

export class TimeOffsetModel {
  constructor (db) {
    this.db = db;
    this._timeOffset = 0;
    _.bindAll(this, 'handleUpdateTimeOffset');
  }
  handleUpdateTimeOffset (snap) {
    let timeOffset = snap.val();
    if (Math.abs(this._timeOffset - timeOffset) > 1000) {
      this._timeOffset = timeOffset;
    }
  }
  addDbListeners () {
    this.removeDbListeners();
    this.db.root
      .child('.info/serverTimeOffset')
      .on('value', this.handleUpdateTimeOffset);
  }
  removeDbListeners () {
    this.db && this.db.root.child('.info/serverTimeOffset').off();
  }
  get timeOffset () { return this._timeOffset; }
}
