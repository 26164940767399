import {closebutton} from '../img';
import {Overlay} from './Overlay';
import { propTypes as $p, React, underscore as _ } from '../../deps';
import {setOverlay} from '../util/runtime';

import './Dialog.css'

export function Dialog (props) {
  let {children, className, disabled, onClose, style, role, cardDeck} = props;
  return (
    <Overlay {...{disabled}}>

      <div role={role || "dialog"} className={`Dialog ${className || ''}`}
        {...{style}}>

        <div className='Dialog-bg'
          onClick={onClose}/>

        <button className='Dialog-close'
          onClick={onClose}>

          <img
            src={closebutton.src}
            alt="close dialog"
            style={{
              height: closebutton.height / 2,
              width: closebutton.width / 2
            }}/>

        </button>

        <div className='Dialog-content' style={{padding: cardDeck ? 0 : '30px' }}>

          {children}

        </div>

      </div>

    </Overlay>
  );
}
Dialog.displayName = 'Dialog';
