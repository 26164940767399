export const char = {
  ldquo: '\u201c',
  lsquo: '\u2018',
  mdash: '\u2014',
  nbsp: '\u00a0',
  rdquo: '\u201d',
  rsquo: '\u2019'
};

export function series (arr, sep=', ', lastSep=' and ') {
  let thisSep = sep;
  switch (arr.length) {
    case 0: return [];
    case 1: return [arr[0]];
    case 2: thisSep = lastSep;  // eslint-disable-line no-fallthrough
    default: return [arr[0], thisSep].concat(
      series(arr.slice(1), sep, lastSep)
    );
  }
}

export function joinSeries (arr, sep=', ', lastSep=' and ') {
  return series(arr, sep, lastSep).join('');
}

export function num (v) { return ('' + v); }

export function plural (v, one, many) {
  return (v === 1) ? one : (many || one);
}
