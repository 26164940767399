import { Component } from '../component'
import { React } from '../deps'

export default function withTimeOffset (timeOffsetModel) {
  return (Wrapped) => {
    class TimeOffset extends Component {
      constructor (props) {
        super(props);
        this.state = { getTimeOffset: () => 0 };
      }
      componentDidMount () {
        timeOffsetModel.addDbListeners();
        this.setState({getTimeOffset: () => timeOffsetModel.timeOffset});
      }
      componentWillUnmount () { timeOffsetModel.removeDbListeners(); }
      render () {
        return <Wrapped
          getTimeOffset={this.state.getTimeOffset}
          {...this.props}/>;
      }
    }
    TimeOffset.displayName = `TimeOffset(${Wrapped.displayName})`;
    return TimeOffset;
  };
}
