import { propTypes as $p, React, underscore as _ } from '../../deps'

export const ErrorInfo = (props) => (!props.error) ? null : (
  <div className="error"
    style={props.style}>

    {props.error}

  </div>
);
ErrorInfo.displayName = `ErrorInfo`;
