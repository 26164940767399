import * as Comp from './component'
import { PointRow } from './RoundEnd'
import { propTypes as $p, React, underscore as _ } from '../deps'
import {
  answerFleurLeft0,
  answerFleurLeft1,
  cardDeck,
  gramaphone,
  pocketwatch,
  point0Big,
  point0Small,
  point1Big,
  point1Small,
  ribbonSmall,
  tophat,
  typewriter
} from './img';
import * as fmt from './util/fmt';
import { factVoteKey } from './util/app';
import { invertGrouped, sortedPairs } from './util'

const fleur = [
  {src: answerFleurLeft0.src, h: answerFleurLeft0.height / 2, w: answerFleurLeft0.width / 2},
  {src: answerFleurLeft1.src, h: answerFleurLeft1.height / 2, style: {marginBottom: 8}, w: answerFleurLeft1.width / 2}
]

function lieOfTheGame(rounds) {
  let topLie = "";
  let topLieScore = 0;
  rounds.forEach(round => {
    let allVoteInfos = invertGrouped(round.votes || {});
    let factVoteInfo = allVoteInfos[factVoteKey] || [];
    let voteInfoPairs = sortedPairs(allVoteInfos, pks => pks.length)
      .filter(([v, pks]) => v !== factVoteKey)
      .concat([[factVoteKey, factVoteInfo]]);

    voteInfoPairs.forEach(voteInfoPair => {
      if (voteInfoPair[1].length > topLieScore && voteInfoPair[0] !== factVoteKey) {
        topLie = round.answers[voteInfoPair[0]];
        topLieScore = voteInfoPair[1].length;
      }
    });
  });
  return { topLie, topLieScore };
}

export default class Scoreboard extends React.Component {
  constructor (props) {
    super(props);
    _.bindAll(this, 'handleClickDone');
  }
  handleClickDone (e) {
    this.props.onDone();
  }
  render() {
    let {
      announcerPlayerKey,
      answerPairs,
      answersLeft,
      answers,
      answerTimerStartedAt,
      answerTimeout,
      card,
      connected,
      players,
      room,
      roomCode,
      roomCreatedAt,
      roundIndex,
      timeOffset,
      typoRound,
      voteAnswerIndex,
      voteInfoPair,
      voteInfoPairs,
      votesLeft,
      voteTimerStartedAt,
      voteTimeout
    } = this.props;
    let { allPlayersPresentAt, hostPlayerKey, rounds } = room;
    let voteKey = voteInfoPair ? voteInfoPair[0] : null;
    let votePlayers = voteInfoPair ? _.pick(players, ...(voteInfoPair[1])) : null;
    let voteInfoPairsLies = voteInfoPairs ? voteInfoPairs.filter(pair => pair[0] !== factVoteKey) : null;
    let voteInfoPairTruth = voteInfoPairs ? voteInfoPairs.find(pair => pair[0] === factVoteKey) : null;
    
    const total = (s) => ((s.truth || 0) + (s.lie || 0));
    const add = (v, prop) => (v[prop] = (v[prop] || 0) + 1);
    let playerScores = {};
    let sortedScorePairs = [];
    let maxTotalScore = 0;
    if (room.rounds && roundIndex !== null) {
      playerScores = _.range(roundIndex + 1)
      .reduce((res, i) => (
        _.reduce((((room.rounds || [])[i] || {}).votes || {}), (res0, vk, pk) => {
          (vk === factVoteKey) ? add(res0[pk], 'truth') : add(res0[vk], 'lie');
          return res0;
        }, res)
      ), _.mapObject(players, () => ({})));
      sortedScorePairs = _.chain(playerScores)
        .pairs()
        .sortBy(([pk, p]) => -total(p))
        .value();
      maxTotalScore = total(sortedScorePairs[0][1]);
    }
    // game states
    let awaitPlayers = (room && !allPlayersPresentAt);
    let selectDeck = (room && allPlayersPresentAt && !rounds);
    let enterAnswer = (room && roundIndex !== null && rounds && rounds[roundIndex] && card && (_.size(answersLeft) > 0));
    let announceAnswers = (room && roundIndex !== null && rounds && rounds[roundIndex] && !rounds[roundIndex].announcedAnswers && (_.size(answersLeft) === 0));
    let enterVote = (room && roundIndex !== null && rounds && rounds[roundIndex] && rounds[roundIndex].announcedAnswers && (_.size(votesLeft) > 0));
    let announceVote = (room &&
      roundIndex !== null &&
      rounds && rounds[roundIndex] &&
      rounds[roundIndex].announcedAnswers &&
      (_.size(votesLeft) === 0) &&
      (rounds[roundIndex].announcedVotes || 0) < voteInfoPairs.length);
    let roundEnd = (room &&
      roundIndex !== null &&
      rounds && rounds[roundIndex] &&
      rounds[roundIndex].announcedAnswers &&
      (_.size(votesLeft) === 0) &&
      rounds[roundIndex].announcedVotes === voteInfoPairs.length);
    let isGameOver = room &&
      roundIndex !== null && 
      rounds && 
      rounds[roundIndex] &&
      !rounds[roundIndex + 1] &&
      rounds[roundIndex].announcedAnswers &&
      (_.size(votesLeft) === 0) &&
      rounds[roundIndex].announcedVotes === voteInfoPairs.length;

    if (!isGameOver) {
      return (
        <Comp.Main
          className='Scoreboard'
          style={{ position: 'relative', width: '100%', minHeight: 0, paddingTop: '56.25%' }}
          overlay={<Comp.OfflineOverlay disabled={connected}/>}
        >
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            height: 'calc(100vw * 0.5625)'
          }}>
          { ((answerTimerStartedAt && enterAnswer) || (voteTimerStartedAt && enterVote)) && connected &&
            <Comp.Countdown
              answersLeft={answersLeft}
              votesLeft={votesLeft}
              scoreboardAnswering={enterAnswer}
              scoreboardVoting={enterVote}
              startAt={enterVote ? voteTimerStartedAt : answerTimerStartedAt}
              timeOffset={timeOffset}
              timeout={enterVote ? voteTimeout : answerTimeout}
            />
          }
          <Comp.Header
            scoreboardMode
            hideLogo={((answerTimerStartedAt && enterAnswer) || (voteTimerStartedAt && enterVote)) && connected}
            style={{margin: 0}}
          />
          <div className="scoreboard-container">
            <div className="scoreboard-players">
              {_.size(players) === 0 &&
                <h2 className="scoreboard-heading">Players</h2>
              }
              {!rounds
                ? <Comp.AvatarList
                  players={players}
                  style={{marginBottom: '1vw'}}
                  avatarStyle={{width: '6vw', maxWidth: '80%', marginBottom: '0.5vw', height: '6vw', maxHeight: '80%'}}
                  imgStyle={{width: '100%', height: 'none'}}
                  playerStyle={{fontSize: '1.25vw', margin: '0 0.5vw 2vw 0.5vw'}}
                  {...{roomCreatedAt}}
                />
                : <ul
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    zIndex: (connected ? 0 : -1)
                  }}>

                  {sortedScorePairs.map(([pk, ps], i) => (
                    <li key={pk}
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        textAlign: 'center',
                        justifyContent: 'center',
                        width: '50%'
                      }}>

                      <Comp.Avatar
                        playerIndex={players[pk].index}
                        imgStyle={{width: '100%', height: 'none'}}
                        style={{width: '6vw', maxWidth: '80%', marginBottom: '0.5vw', height: '6vw', maxHeight: '80%'}}
                        roomCreatedAt={roomCreatedAt}
                      />

                      <div style={{marginBottom: '2vw'}}>

                        <Comp.Player style={{fontSize: '1.25vw', lineHeight: '1vw'}} player={players[pk]}/>

                        {enterVote || announceVote 
                          ? <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                marginTop: '0.2vw'
                              }}
                            >
                              <span style={{fontStyle: 'italic', fontSize: '1.25vw', lineHeight: '1vw'}}>Calculating...</span>
                            </div>
                          : <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                marginTop: '0.2vw'
                              }}
                            >
                              {_.range(ps.lie || 0).map(i => (
                              <img
                                key={i}
                                src={point0Small.src}
                                alt="lie point"
                                style={{
                                  height: '1.25vw',
                                  width: '1.25vw',
                                }}
                              />
                            ))}

                            {_.range(ps.truth || 0).map(i => (
                              <img
                                key={i}
                                src={point1Small.src}
                                alt="truth point"
                                style={{
                                  height: '1.25vw',
                                  width: '1.25vw',
                                }}
                              />
                            ))}
                          </div>
                        }
                      </div>
                    </li>
                  ))}
                </ul>
              }
              {_.size(players) === 0 &&
                <p className="scoreboard-text">Players will appear here when they join the room.</p>
              }
            </div>
            <div className="scoreboard-main">
              {awaitPlayers &&
                <div className="scoreboard-main-await-players">
                  <h2 className="scoreboard-heading">Room Code</h2>
                  <Comp.RoomCode
                    roomCode={roomCode}
                    style={{
                      width: '100%',
                      fontSize: '2vw'
                    }}
                  />
                  <Comp.UrlTip style={{marginTop: '1.5vw', marginBottom: '2vw', fontSize: '1.5vw', lineHeight: '2vw',}} />
                  <img
                    src={pocketwatch.src}
                    alt="pocketwatch"
                    style={{
                      margin: '0 auto 2vw auto',
                      width: '10vw',
                    }}
                  />
                  {players && hostPlayerKey && players[hostPlayerKey] && players[hostPlayerKey].name
                    ? <h2 className="scoreboard-heading">When all players are in <Comp.Player player={players[hostPlayerKey]}/> should start the game</h2>
                    : <h2 className="scoreboard-heading">When all players are in the leader should start the game</h2>
                  }
                </div>
              }
              {selectDeck &&
                <div className="scoreboard-main-select-deck">
                  <img
                    alt="deck of cards"
                    src={cardDeck.src}
                    style={{
                      margin: '0 auto 2vw auto',
                      width: '30vw',
                    }}
                  />
                  <h2 className="scoreboard-heading">
                    <Comp.Player player={players[hostPlayerKey]}/> is selecting a card deck
                  </h2>
                </div>
              }
              {enterAnswer &&
                <div className="scoreboard-main-enter-answer">
                  <img
                    alt="typewriter"
                    src={typewriter.src}
                    style={{
                      margin: '0 auto 2vw auto',
                      width: '25vw',
                    }}
                  />
                  <h2 className="scoreboard-heading">
                    This round's topic is: {card.topic}
                  </h2>
                </div>
              }
              {announceAnswers &&
                <div className="scoreboard-main-announce-answers">
                  <img
                    alt="gramaphone"
                    src={gramaphone.src}
                    style={{
                      margin: '0 auto 2vw auto',
                      width: '20vw',
                      filter: (connected ? 'brightness(0)' : 'none'),
                      zIndex: (connected ? 0 : -1)
                    }}
                  />
                  <h2 className="scoreboard-heading">
                    <Comp.Player player={players[announcerPlayerKey]}/> will now read the facts out loud
                  </h2>
                </div>
              }
              {enterVote &&
                <div className="scoreboard-main-enter-vote">
                  <h2 className="scoreboard-heading">Which one is true?</h2>
                  <div className="scoreboard-main-enter-vote-answers">

                    {answerPairs.map(([k, a], i) => (

                      <div
                        key={k}
                        style={{marginBottom: '2vw', width: '33%', padding: '0 1vw'}}>

                        <div className='flex'
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            marginBottom: '1.5vw'
                          }}>

                          <img 
                            src={fleur[i % fleur.length].src}
                            alt=""
                            style={{
                              zIndex: -1,
                              width: '6vw',
                              ...fleur[i % fleur.length].style
                            }}/>

                          <div
                            style={{
                              fontSize: '2.5vw',
                              margin: '0 1.5vw',
                              textAlign: 'center',
                              verticalAlign: 'middle'
                            }}>

                            {fmt.num(i + 1)}

                          </div>

                          <img 
                            src={fleur[i % fleur.length].src}
                            alt=""
                            style={{
                              zIndex: -1,
                              transform: `scale(-1, 1)`,
                              width: '6vw',
                              ...fleur[i % fleur.length].style
                            }}/>

                        </div>
                        {k === factVoteKey && typoRound && card.typo
                          ? <p>{card.typo}</p>
                          : <p style={{wordBreak: 'break-word'}}>{a}</p>
                        }

                      </div>

                    ))}
                  </div>
                </div>
              }
              {announceVote &&
                <div className="scoreboard-main-announce-vote">
                  {(voteKey === factVoteKey) ? (
                    <React.Fragment>

                      <h2 className="scoreboard-heading" style={{marginBottom: '4vw'}}>
                        {(_.size(votePlayers) < 1) ? (
                          'Nobody guessed'
                        ) : (
                          <React.Fragment>
                            <Comp.PlayerSeries players={votePlayers}/>{' '}
                            guessed
                          </React.Fragment>
                        )}
                      </h2>

                      <Comp.Answer
                        style={{
                          padding: '1vw 0'
                        }}
                        numberStyle={{
                          fontSize: '2.5vw',
                          width: '3vw',
                        }}
                        labelStyle={{
                          fontSize: '1.5vw',
                        }}
                        index={voteAnswerIndex}
                        label={typoRound && card.typo
                          ? card.typo
                          : card.fact
                        }
                      />

                      {(!card.bonus) ? null : (
                        <Comp.Answer.Bonus
                          imgStyle={{
                            height: '2.5vw',
                            width: '2.5vw',
                            marginRight: '1vw',
                          }}
                          textStyle={{
                            fontSize: '1.5vw',
                          }}
                        >
                          {card.bonus}
                        </Comp.Answer.Bonus>
                      )}

                      <Comp.Hr style={{marginBottom: '1.5vw', marginTop: '1.5vw'}}/>
                      
                      <p className="scoreboard-text" style={{marginBottom: '1.5vw'}}>
                        <strong>Which was the truth!</strong>
                      </p>

                      {(_.size(votePlayers) < 1) ? (
                        <PointRow
                          pointImg={point1Big}
                          style={{
                            alignSelf: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          pointStyle={{
                            width: '5vw',
                            height: '5vw',
                            opacity: 0.333,
                            background: (!point1Big.src) ? null : `url('${point1Big.src}') center / 5vw 5vw no-repeat`
                          }}
                          pointLabelStyle={{
                            fontSize: "1.4vw"
                          }}
                        />
                      ) : (
                        <PointRow
                          pointLabel={1}
                          pointImg={point1Big}
                          style={{
                            alignSelf: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          pointStyle={{
                            width: '5vw',
                            height: '5vw',
                            background: (!point1Big.src) ? null : `url('${point1Big.src}') center / 5vw 5vw no-repeat`
                          }}
                          pointLabelStyle={{
                            fontSize: "1.4vw"
                          }}
                        />
                      )}

                    </React.Fragment>
                  ) : (
                    <React.Fragment>

                      <h2 className="scoreboard-heading" style={{marginBottom: '4vw'}}>
                        <Comp.PlayerSeries players={votePlayers}/>{' '}
                        guessed
                      </h2>

                      <Comp.Answer
                        style={{
                          padding: '1vw 0'
                        }}
                        numberStyle={{
                          fontSize: '2.5vw',
                          width: '3vw',
                        }}
                        labelStyle={{
                          fontSize: '1.5vw',
                        }}
                        index={voteAnswerIndex}
                        label={answers[voteKey]}/>

                      <Comp.Hr style={{marginBottom: '1.5vw', marginTop: '1.5vw'}}/>

                      <p className="scoreboard-text" style={{marginBottom: '1.5vw'}}>
                        <strong>Which was a lie told by{' '}<Comp.Player player={players[voteKey]}/>.</strong>
                      </p>

                      <PointRow
                        pointLabel={votePlayers ? fmt.num(Object.keys(votePlayers).length) : null}
                        pointImg={point0Big}
                        style={{
                          alignSelf: 'center',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                        pointStyle={{
                          width: '5vw',
                          height: '5vw',
                          background: (!point0Big.src) ? null : `url('${point0Big.src}') center / 5vw 5vw no-repeat`
                        }}
                        pointLabelStyle={{
                          fontSize: "1.4vw"
                        }}
                      />

                    </React.Fragment>
                  )}
                </div>
              }
              {roundEnd &&
                <div className="scoreboard-main-round-end">
                  <div className="scoreboard-main-round-end-heading">
                    <h2 className="scoreboard-heading">Round Summary</h2>
                    <hr />
                  </div>
                  <div className="scoreboard-main-round-end-facts">
                    {voteInfoPairsLies.length > 0 &&
                      <div className="scoreboard-main-round-end-lies">
                        <h3 className="scoreboard-heading">Lies</h3>
                        <div className="scoreboard-main-round-end-lies-info">
                          {voteInfoPairsLies.map(([liar, guessers], i) => {
                            return (
                              <div key={i}
                                style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-evenly',
                                width: (voteInfoPairsLies.length === 1 ? '100%' : '50%'),
                                padding: '0 0.5vw'
                              }}>
                                <p style={{fontSize: '1.25vw'}}>{answers[liar]}</p>
                                <p style={{fontStyle: 'italic', lineHeight: '3vw', fontSize: '1vw'}}>Guessed by</p>
                                <div 
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-evenly'
                                }}>
                                  {guessers.length > 0 ? guessers.map((guesser, j) => {
                                    return (
                                      <div
                                        key={j}
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          flexWrap: 'wrap',
                                          justifyContent: 'center'
                                      }}>
                                        <img
                                          src={point0Small.src}
                                          alt="lie point"
                                          style={{
                                            height: '1.5vw',
                                            width: '1.5vw',
                                            marginLeft: '0.5vw',
                                            marginRight: '0.5vw'
                                          }}
                                        />
                                        <span className="scoreboard-text">{players[guesser].name}</span>
                                      </div>
                                    )
                                  }) : <span className="scoreboard-text">Nobody</span>}
                                </div>
                                <br />
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    }
                    <div 
                      className="scoreboard-main-round-end-truth"
                      style={{
                        width: (voteInfoPairsLies.length > 0 ? '33%' : '100%'),
                      }}
                    >
                      <h3 className="scoreboard-heading">Truth</h3>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly'
                      }}>
                        <p style={{fontSize: '1.25vw'}}>{typoRound ? card.typo : answers[factVoteKey]}</p>
                        <p style={{fontStyle: 'italic', lineHeight: '3vw', fontSize: '1vw'}}>Identified by</p>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          justifyContent: 'space-evenly'
                        }}>
                          {voteInfoPairTruth[1].length > 0 ? voteInfoPairTruth[1].map((guesser, j) => {
                            return (
                              <div key={j}
                                style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                              }}>
                                <img
                                  src={point1Small.src}
                                  alt="truth point"
                                  style={{
                                    height: '1.5vw',
                                    width: '1.5vw',
                                    marginLeft: '0.5vw',
                                    marginRight: '0.5vw'
                                  }}
                                />
                                <span className="scoreboard-text">{players[guesser].name}</span>
                              </div>
                            )
                          }) : <span className="scoreboard-text">Nobody</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          </div>
        </Comp.Main>
      )
    } else { // isGameOver
      let topLiar = sortedScorePairs.reduce((prev, current) => ((prev[1].lie || 0) > (current[1].lie || 0)) ? prev : current);
      let topLieScore = topLiar[1].lie || 0;
      let topLiars = sortedScorePairs.filter(pair => pair[1].lie === topLieScore);
      let topLiarPlayers = {};
      topLiars.forEach(liar => {
        topLiarPlayers[liar[0]] = players[liar[0]];
      })
      let topLieInfo = lieOfTheGame(rounds);
      return (
        <Comp.Main
          className='Scoreboard'
          style={{padding: '0px 30px'}}
          overlay={<Comp.OfflineOverlay disabled={connected}/>}
        >
          <Comp.Header scoreboardMode gameOver />
          <React.Fragment>

            <h2>
              Final Scores
            </h2>

            <ul
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: 48,
                marginLeft: 0,
                marginRight: 0,
                marginTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                zIndex: (connected ? 0 : -1)
              }}>

              {sortedScorePairs.map(([pk, ps], i) => (
                <li key={pk}
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    textAlign: 'center',
                    width: '50%'
                  }}>

                  <div className='flex'
                    style={{
                      position: 'relative'
                    }}>

                    <Comp.Avatar playerIndex={players[pk].index}
                      style={{
                        alignSelf: 'center',
                        marginBottom: 8
                      }}
                      roomCreatedAt={roomCreatedAt}/>

                    <Comp.Img.Aspect 
                      img={ribbonSmall}
                      alt=""
                      style={{
                        bottom: 0,
                        display: (maxTotalScore === total(ps)) ? 'block' : 'none',
                        left: 0,
                        position: 'absolute',
                        right: 0,
                        width: '100%'
                      }}
                    />

                    <div className='noselect'
                      style={{
                        bottom: 0,
                        color: '#E5CF96',
                        display: (maxTotalScore === total(ps)) ? 'block' : 'none',
                        fontSize: 15,
                        left: 0,
                        paddingBottom: 7,
                        position: 'absolute',
                        right: 0
                      }}>
                      Winner
                    </div>

                  </div>

                  <div>

                    <Comp.Player player={players[pk]}/>

                    <div>
                      {fmt.num((ps.lie || 0) + (ps.truth || 0))}
                      pts
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        marginTop: 4
                      }}>

                      {_.range(ps.lie || 0).map(i => (
                        <img
                          key={i}
                          src={point0Small.src}
                          alt="lie point"
                          style={{
                            height: point0Small.height / 2,
                            width: point0Small.width / 2
                          }}
                        />
                      ))}

                      {_.range(ps.truth || 0).map(i => (
                        <img
                          key={i}
                          src={point1Small.src}
                          alt="truth point"
                          style={{
                            height: point1Small.height / 2,
                            width: point1Small.width / 2
                          }}
                        />
                      ))}
                    </div>

                  </div>

                </li>
              ))}

            </ul>

            <Comp.Hr style={{marginBottom: 32, marginTop: 0}}/>

          </React.Fragment>
          <div className="scoreboard-top-stats">
            <div className="scoreboard-top-lie">
              {topLieInfo && topLieInfo.topLie !== "" && topLieInfo.topLieScore > 0 ?
                <div>
                  <h2>Lie of the Game</h2>
                  <img
                    src={tophat.src}
                    alt="tophat"
                    style={{
                      margin: '60px auto 12px auto',
                      height: tophat.height / 1.75,
                      width: tophat.width / 1.75
                    }}
                  />
                  <p style={{marginBottom: 24}}>{topLieInfo.topLie}</p>
                </div>
                : <p>No one guessed anyone's lies, everyone identified the truth!</p>
              }
            </div>
            <div className="scoreboard-top-liar">
              {topLiars.length > 0 ?
                (topLiars.length === 1 ? 
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <h2>Top Liar</h2>
                    <Comp.AvatarList
                      players={topLiarPlayers}
                      roomCreatedAt={roomCreatedAt}
                      style={{
                        alignSelf: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        marginBottom: 20,
                      }}
                    />
                  </div>
                : <div>
                    <h2>Top Liars</h2>
                    <Comp.AvatarList
                      players={topLiarPlayers}
                      roomCreatedAt={roomCreatedAt}
                      style={{
                        alignSelf: 'center',
                        marginBottom: 20,
                      }}
                    />
                  </div>
                )
              : <p>No one's lies were guessed! Everyone must be really good at this game!</p>}
            </div>
          </div>
          <Comp.Hr style={{marginBottom: 32, marginTop: 32}}/>
          <div className="scoreboard-main-round-end-game-over">
            <h2>Round Summary</h2>
            <hr />
            {voteInfoPairsLies.length > 0 &&
              <h3>Lies</h3>
            }
            {voteInfoPairsLies.map(([liar, guessers], i) => {
              return (
                <div key={i}
                  style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-evenly'
                }}>
                  <p style={{fontSize: 24, wordBreak: 'break-word', padding: '0 30px'}}>{answers[liar]}</p>
                  <br />
                  <p style={{fontStyle: 'italic'}}>Guessed by</p>
                  <br />
                  <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'space-evenly'
                  }}>
                    {guessers.length > 0 ? guessers.map((guesser, j) => {
                      return (
                        <div key={j}
                          style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center'
                        }}>
                          <img
                            src={point0Small.src}
                            alt="lie point"
                            style={{
                              height: point0Small.height / 2,
                              width: point0Small.width / 2,
                              marginRight: '5px'
                            }}
                          />
                          <span>{players[guesser].name}</span>
                        </div>
                      )
                    }) : "Nobody"}
                  </div>
                  <br />
                </div>
              )
            })}
            {voteInfoPairsLies.length > 0 &&
              <hr />
            }
            <h3>Truth</h3>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly'
            }}>
              <p style={{fontSize: 24}}>{typoRound && card.typo ? card.typo : answers[factVoteKey]}</p>
              <br />
              <p style={{fontStyle: 'italic'}}>Identified by</p>
              <br />
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly'
              }}>
                {voteInfoPairTruth[1].length > 0 ? voteInfoPairTruth[1].map((guesser, j) => {
                  return (
                    <div key={j}
                      style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}>
                      <img
                        src={point1Small.src}
                        alt="truth point"
                        style={{
                          height: point1Small.height / 2,
                          width: point1Small.width / 2,
                          marginRight: '5px'
                        }}
                      />
                      <span>{players[guesser].name}</span>
                    </div>
                  )
                }) : "Nobody"}
              </div>
            </div>
          </div>
          <Comp.Hr style={{marginBottom: 32, marginTop: 32}}/>
          <input style={{marginTop: 32, marginBottom: 32}}
            type="button"
            value='New Game'
            onClick={this.handleClickDone}
          />
        </Comp.Main>
      )
    }
  }
}

Scoreboard.displayName = `Scoreboard`;