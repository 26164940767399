import {pocketwatch} from './img';
import config from './util/config'
import * as Comp from './component'
import { propTypes as $p, React, underscore as _ } from '../deps'
import * as fmt from './util/fmt'
import ReactGA from 'react-ga';

export default class AwaitPlayers extends React.Component {
  constructor (props) {
    super(props);

    _.bindAll(this,
      'handlePlay'
    );
  }

  handlePlay () {
    const { onClickStart, players } = this.props;
    ReactGA.event({
      category: 'Games',
      action: 'Game-Start',
      label: 'Start Game',
      value: _.size(players)
    });
    onClickStart();
  }

  render () {
    let {props: {cardSets, roomCreatedAt}} = this;
    let leaderPlayerKey = this.props.leaderPlayerKey;
    let isLeader = leaderPlayerKey === this.props.selfPlayerKey;
    let players = this.props.players;
    let playerCount = _.size(players);
    let leader = players[leaderPlayerKey];

    return (
      <Comp.Main
        className='AwaitPlayers'
        overlay={<Comp.OfflineOverlay disabled={this.props.connected} />}
      >

        <Comp.Header/>

        <Comp.RoomCodeHeader {...this.props}
          style={{marginBottom: 32}}/>

        {(isLeader) ? null : (
          <React.Fragment>

            <h1>
              {(leader.name) ? <Comp.Player player={leader}/> : 'The leader'}{' '}
              will start the game
            </h1>

            <img 
              src={pocketwatch.src}
              alt="pocketwatch"
              style={{
                margin: '0 auto 48px auto',
                height: pocketwatch.height / 2,
                width: pocketwatch.width / 2
              }}
            />

            <hr style={{marginBottom: 32}}/>

          </React.Fragment>
        )}

        <h1>
          Players
        </h1>

        <Comp.AvatarList players={players}
          style={{marginBottom: 48}}
          {...{roomCreatedAt}}/>

        {(!isLeader) ? null : (
          <LeaderInfo 
            onClickStart={this.handlePlay}
            {...{cardSets, players}}
          />
        )}

      </Comp.Main>
    );
  }
}
AwaitPlayers.displayName = `AwaitPlayers`;

function LeaderInfo (props) {
  let {cardSetKey, cardSets, children, onClickStart, players,
    style, ...thruProps} = props;

  let playerCount = _.size(players);
  let playersLeft = config.minPlayers - playerCount;

  let playerNamesLeft = playerCount -
    _.filter(players, p => !!p.name).length;

  return (
    <React.Fragment>
      {(playersLeft > 0) ? (
        <p style={{textAlign: 'center'}}>
          Waiting for {fmt.num(playersLeft)} more{' '}
          {fmt.plural(playersLeft, 'player', 'players')}
        </p>
      ) : (
        (playerNamesLeft > 0) ? (
          <p style={{textAlign: 'center'}}>
            Waiting for {fmt.num(playerNamesLeft)}{' '}
            {fmt.plural(playerNamesLeft, 'player to pick a name', 'players to pick names')}
          </p>
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
            <input 
              type='button'
              value='START GAME'
              onClick={onClickStart}
            />
          </div>
        )
      )}
    </React.Fragment>
  );
}
