export {Answer} from './Answer';
export {Countdown} from './Countdown';
export {Dialog} from './Dialog';
export {ErrorInfo} from './ErrorInfo';
export {Header} from './Header';
export {Hr} from './Hr';
export {Img, ImgAspect} from './Img';
export {Main} from './Main';
export { OfflineOverlay } from './OfflineOverlay';
export {Avatar, AvatarList, Player, PlayerSeries} from './Player';
export {RoomCode, RoomCodeHeader, UrlTip} from './RoomCode';
export {TypoTip} from './TypoTip';

export const errorClass = (self) => (self.state.error) ? 'error' : '';
