import answerFleurLeft0Src from '../static/answer-fleur-left.0.png';
export const answerFleurLeft0 = {
  height: 64,
  src: answerFleurLeft0Src,
  width: 160
};

import answerFleurLeft1Src from '../static/answer-fleur-left.1.png';
export const answerFleurLeft1 = {
  height: 30,
  src: answerFleurLeft1Src,
  width: 146
};

import caretrightButtonSrc from '../static/caretrightbutton.png';
export const caretrightButton = {
  height: 42,
  src: caretrightButtonSrc,
  width: 26
};

import closebuttonSrc from '../static/closebutton.png';
export const closebutton = {
  height: 24,
  src: closebuttonSrc,
  width: 26
};

import gramaphoneSrc from '../static/gramaphone.png';
export const gramaphone = {
  height: 318,
  src: gramaphoneSrc,
  width: 348
};

import infoSrc from '../static/info.png';
export const info = {
  height: 68,
  src: infoSrc,
  width: 68
};

import logomainSrc from '../static/liesgamelogo.png';
export const logomain = {
  height: 214,
  src: logomainSrc,
  width: 335
};

import logominiSrc from '../static/logomini.png';
export const logomini = {
  height: 130,
  src: logominiSrc,
  width: 120
};

import pocketwatchSrc from '../static/pocketwatch.png';
export const pocketwatch = {
  height: 360,
  src: pocketwatchSrc,
  width: 254
};

import point0BigSrc from '../static/point0.big.png';
export const point0Big = {
  height: 116,
  src: point0BigSrc,
  width: 118
};

import point0SmallSrc from '../static/point0.small.png';
export const point0Small = {
  height: 36,
  src: point0SmallSrc,
  width: 38
};

import point0Src from '../static/point0.png';
export const point0 = {
  height: 58,
  src: point0Src,
  width: 60
};

import point1BigSrc from '../static/point1.big.png';
export const point1Big = {
  height: 116,
  src: point1BigSrc,
  width: 118
};

import point1SmallSrc from '../static/point1.small.png';
export const point1Small = {
  height: 36,
  src: point1SmallSrc,
  width: 38
};

import point1Src from '../static/point1.png';
export const point1 = {
  height: 58,
  src: point1Src,
  width: 60
};

import ribbonSrc from '../static/ribbon.png';
export const ribbon = {
  height: 92,
  src: ribbonSrc,
  width: 408
};

import ribbonSmallSrc from '../static/ribbon.small.png';
export const ribbonSmall = {
  height: 72,
  src: ribbonSmallSrc,
  width: 304
};

import typewriterSrc from '../static/typewriter.png';
export const typewriter = {
  height: 234,
  src: typewriterSrc,
  width: 290
};

import glassesSrc from '../static/glasses.png';
export const glasses = {
  height: 124,
  src: glassesSrc,
  width: 298
};

import cardDeckSrc from '../static/carddeck.png';
export const cardDeck = {
  height: 168,
  src: cardDeckSrc,
  width: 348
};

import trainSrc from '../static/train.png';
export const train = {
  height: 264,
  src: trainSrc,
  width: 346
};

import cameraSrc from '../static/camera.png';
export const camera = {
  height: 241,
  src: cameraSrc,
  width: 168
};

import tophatSrc from '../static/tophat.png';
export const tophat = {
  height: 162,
  src: tophatSrc,
  width: 156
};

import emailSrc from '../static/email.png';
export const email = {
  height: 30,
  src: emailSrc,
  width: 30
};

import twitterSrc from '../static/twitter_logo.png';
export const twitter = {
  height: 30,
  src: twitterSrc,
  width: 30
};

import instagramSrc from '../static/instagram_logo.png';
export const instagram = {
  height: 30,
  src: instagramSrc,
  width: 30
};

import avatar0 from '../static/avatar/avatar.0.png';
import avatar1 from '../static/avatar/avatar.1.png';
import avatar2 from '../static/avatar/avatar.2.png';
import avatar3 from '../static/avatar/avatar.3.png';
import avatar4 from '../static/avatar/avatar.4.png';
import avatar5 from '../static/avatar/avatar.5.png';
import avatar6 from '../static/avatar/avatar.6.png';
import avatar7 from '../static/avatar/avatar.7.png';
import avatar8 from '../static/avatar/avatar.8.png';
import avatar9 from '../static/avatar/avatar.9.png';
import avatar10 from '../static/avatar/avatar.10.png';
import avatar11 from '../static/avatar/avatar.11.png';
import avatar12 from '../static/avatar/avatar.12.png';
import avatar13 from '../static/avatar/avatar.13.png';
import avatar14 from '../static/avatar/avatar.14.png';
import avatar15 from '../static/avatar/avatar.15.png';

export const avatars = [
  {src: avatar0, h: 336, w: 294, alt: "avatar: light-skinned woman with brown hair"},
  {src: avatar1, h: 393, w: 285, alt: "avatar: light-skinned male pirate with mustache"},
  {src: avatar2, h: 288, w: 312, alt: "avatar: colorful parrot"},
  {src: avatar3, h: 321, w: 288, alt: "avatar: light-skinned woman with short black hair wearing a blue dress"},
  {src: avatar4, h: 324, w: 279, alt: "avatar: dark-skinned man with mustache"},
  {src: avatar5, h: 324, w: 369, alt: "avatar: light-skinned blonde child wearing a bucket on their head"},
  {src: avatar6, h: 345, w: 348, alt: "avatar: light-skinned man with long dark hair and mustache"},
  {src: avatar7, h: 381, w: 315, alt: "avatar: light-skinned red-haired person"},
  {src: avatar8, h: 321, w: 306, alt: "avatar: horse with reins"},
  {src: avatar9, h: 327, w: 318, alt: "avatar: woman with long dark hair and fancy hat"},
  {src: avatar10, h: 309, w: 285, alt: "avatar: dark-skinned man with gray hair"},
  {src: avatar11, h: 300, w: 285, alt: "avatar: woman with white headdress"},
  {src: avatar12, h: 351, w: 282, alt: "avatar: dark-skinned person with yellow hat and red earrings"},
  {src: avatar13, h: 339, w: 312, alt: "avatar: tan woman holding a fan"},
  {src: avatar14, h: 348, w: 282, alt: "avatar: dark-skinned person wearing a black pointy hat"},
  {src: avatar15, h: 324, w: 285, alt: "avatar: light-skinned woman with long brown hair and fancy jewelry"}
];
