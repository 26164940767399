import {Hr} from './Hr';
import {logomain, logomini} from '../img';
import { propTypes as $p, React, underscore as _ } from '../../deps'

export function Header (props) {
  return (
    <React.Fragment>

      <div
        className='flex'
        style={{
          margin: props.scoreboardMode && !props.gameOver ? 'none' : '-16px auto 16px auto',
          height: props.scoreboardMode && !props.gameOver ? '12%' : (props.scoreboardMode && props.gameOver ? '140px' : 'none'),
          position: props.scoreboardMode && !props.gameOver && 'relative'
        }}
      >

        <a href=''>
          {props.scoreboardMode ?
            <img
              src={logomain.src}
              alt="Believable Lies"
              style={{
                position: 'absolute',
                height: props.gameOver ? '100px' : '80%',
                top: props.gameOver ? '20px' : '10%',
                left: '50%',
                transform: 'translate(-50%, 0%)',
                zIndex: (props.hideLogo ? -1 : 0),
              }}
            />
          : <img
              src={logomini.src}
              alt="Believable Lies"
              style={{
                display: 'block',
                height: logomini.height / 2,
                width: logomini.width / 2
              }}
            />
          }
        </a>

      </div>

      <Hr style={props.style}/>

    </React.Fragment>
  );
}
Header.displayName = `Header`;
