import { underscore as _ } from '../../deps'
import * as rt from '../../runtime'
import { now as utilNow } from './index'

export default class Timer {
  constructor (props) {
    let nowFn = props.nowFn || utilNow;
    this.props = _.defaults({}, props, {
      clearInterval: rt.window.clearInterval.bind(rt.window),
      nowFn,
      setInterval: rt.window.setInterval.bind(rt.window),
      startAt: nowFn(),
      timeOffset: 0,
      timeout: 0
    });
    this._timeLeft = this.props.timeout;
  }
  get isExpired () { return this._timeLeft === 0; }
  get isTimeoutFinite () { return Number.isFinite(this.props.timeout); }
  start () {
    if (this._intervalId) this.stop();
    const onTick = () => {
      let now0 = (this.props.nowFn() + this.props.timeOffset) / 1000;
      let startAt0 = this.props.startAt / 1000;
      let timeout = this.props.timeout;
      let timeLeft = Math.ceil(Math.max(startAt0 + timeout - now0, 0));
      if (timeLeft !== this._timeLeft) {
        this._timeLeft = timeLeft;
        let expired = this.isExpired;
        if (expired) this.stop();
        if (this.props.onTick) this.props.onTick(timeLeft);
        if (expired && this.props.onExpire) this.props.onExpire();
      }
    };
    if (this.isTimeoutFinite) {
      onTick();
      this._intervalId = this.props.setInterval(onTick, 200);
    }
  }
  stop () {
    if (this._intervalId) {
      this.props.clearInterval(this._intervalId);
      delete this._intervalId;
    }
  }
  get timeLeft () { return this._timeLeft; }
}
