import * as Comp from './component'
import { propTypes as $p, React, underscore as _ } from '../deps'
import * as fmt from './util/fmt'

export default class EnterVote extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      vote: '',
      error: null
    };
    _.bindAll(this, 'handleChange', 'handleSubmit');
  }
  handleChange (e) {
    if (e.target.checked) {
      this.setState({vote: e.target.value});
    }
  }
  handleSubmit (e) {
    e.preventDefault();
    if (this.state.vote === '') {
      this.setState({error: 'You must vote'});
    }
    else this.props.onSubmitVote(this.state.vote);
  }
  render () {
    return (
      <Comp.Main
        className='EnterVote'
        overlay={<Comp.OfflineOverlay disabled={this.props.connected} />}
      >

        { this.props.timerStartedAt && this.props.connected &&
          <Comp.Countdown
            voting
            startAt={this.props.timerStartedAt}
            timeOffset={this.props.timeOffset}
            timeout={this.props.timeout}
          />
        }

        <Comp.Header style={{marginBottom: 32}}/>

        <h1 style={{marginBottom: 48}}>
          Choose the true fact
        </h1>

        <form onSubmit={this.handleSubmit}>

          <section style={{marginBottom: 24}}>

            {this.props.answerPairs.map(([k, a], i, l) => (

              <React.Fragment key={k}>

                <Comp.Answer htmlFor={'vote' + i}
                  index={i}
                  label={k === "_" && this.props.typoRound && this.props.card.typo
                    ? this.props.card.typo
                    : a
                  }
                >

                  <input checked={k === this.state.vote}
                    className={(k === this.props.selfPlayerKey) ? 'hidden' : ''}
                    disabled={k === this.props.selfPlayerKey}
                    id={'vote' + i}
                    name='vote'
                    style={{
                      display: 'block',
                      marginLeft: 16
                    }}
                    type='radio'
                    value={k}
                    onChange={this.handleChange}/>

                </Comp.Answer>

                {(i >= l.length - 1) ? null : (
                  <Comp.Hr style={{marginBottom: 0}}/>
                )}

              </React.Fragment>
            ))}

            <Comp.ErrorInfo error={this.state.error}/>

          </section>

          <input type="submit"
            value="Done"/>

        </form>

      </Comp.Main>
    );
  }
}
EnterVote.displayName = `EnterVote`;
