let _ = require('underscore');

console.log('mixing in underscore');

_ = _.mixin({
  /** equivalent to _.flatten(_.map(...), true) */
  flatMap (list, iteratee, context) {
    let it = _.iteratee(iteratee, context);
    return _.reduce(list, (res, v, k, l) => {
      let next = it(v, k, l);
      if (_.isArray(next)) res.push(...next);
      else if (!_.isUndefined(next) && !_.isNull(next)) res.push(next);
      return res;
    }, []);
  },

  /** equivalent to _.object(_.map(...)) */
  mapToObject (list, iteratee, context) {
    let it = _.iteratee(iteratee, context);
    return _.reduce(list, (res, v, k, l) => {
      let next = it(v, k, l);
      return _.assign(
        res,
        (_.isArray(next) && next.length === 2) ?
          {[next[0]]: next[1]} :
          {[k]: next}
      );
    }, {});
  }
});

module.exports = _;
