import * as Comp from './component';
import {gramaphone, answerFleurLeft0, answerFleurLeft1} from './img';
import { propTypes as $p, React, underscore as _ } from '../deps';
import * as fmt from './util/fmt';

const fleur = [
  {src: answerFleurLeft0.src, h: answerFleurLeft0.height / 2, w: answerFleurLeft0.width / 2},
  {src: answerFleurLeft1.src, h: answerFleurLeft1.height / 2, style: {marginBottom: 8}, w: answerFleurLeft1.width / 2}
]

export default class AnnounceAnswers extends React.Component {
  constructor (props) {
    super(props);
    _.bindAll(this, 'handleClickDone');
  }
  handleClickDone (e) {
    this.props.onDone();
  }
  render () {
    let players = this.props.players;
    let announcerPlayer = players[this.props.announcerPlayerKey];
    let isAnnouncer = this.props.selfPlayerKey === this.props.announcerPlayerKey;

    return (
      <Comp.Main
        className={`AnnounceAnswers ${isAnnouncer ? '' : 'await'}`}
        overlay={<Comp.OfflineOverlay disabled={this.props.connected} />}
      >

        <Comp.Header style={{marginBottom: 32}}/>

        {(isAnnouncer) ? (
          <React.Fragment>

            <h1 style={{marginBottom: 24}}>Read these facts out loud to the group</h1>

            <p style={{marginBottom: 48}}>Only one of them is true.</p>

            <section style={{marginBottom: 24}}>

              {this.props.answerPairs.map(([k, a], i) => (

                <div className='flex'
                  key={k}
                  style={{marginBottom: 48}}>

                  <div className='flex'
                    style={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      marginBottom: 16
                    }}>

                    <img 
                      src={fleur[i % fleur.length].src}
                      alt=""
                      style={{
                        zIndex: -1,
                        width: fleur[i % fleur.length].w,
                        ...fleur[i % fleur.length].style
                      }}/>

                    <div
                      style={{
                        fontSize: 32,
                        margin: '0 16px',
                        textAlign: 'center',
                        verticalAlign: 'middle'
                      }}>

                      {fmt.num(i + 1)}

                    </div>

                    <img 
                      src={fleur[i % fleur.length].src}
                      alt=""
                      style={{
                        zIndex: -1,
                        transform: `scale(-1, 1)`,
                        width: fleur[i % fleur.length].w,
                        ...fleur[i % fleur.length].style
                      }}/>

                  </div>
                  {k === "_" && this.props.typoRound && this.props.card.typo
                    ? <p>{this.props.card.typo}</p>
                    : <p style={{wordBreak: 'break-word'}}>{a}</p>
                  }

                </div>

              ))}

            </section>

            <input style={{textTransform: 'none'}}
              type="button"
              value="I have read them out loud"
              onClick={this.handleClickDone}/>

          </React.Fragment>
        ) : (
          <React.Fragment>

            <h1>
              <Comp.Player player={announcerPlayer}/>{' '}
              will read the facts
            </h1>

            <p style={{marginBottom: 48}}>
              Don{fmt.char.rsquo}t worry if you don{fmt.char.rsquo}t hear{' '}
              them all. You{fmt.char.rsquo}ll get a chance to read them.
            </p>

            <img
              alt=""
              src={gramaphone.src}
              style={{
                alignSelf: 'center',
                height: gramaphone.height / 2,
                maxWidth: '50%',
                width: gramaphone.width / 2
              }}/>

          </React.Fragment>
        )}

      </Comp.Main>
    );
  }
}
AnnounceAnswers.displayName = `AnnounceAnswers`;
