import firebase from '../../firebase'
import * as roomCodeUtil from '../util/roomCode'
import { RoundModel } from './roundModel'
import { underscore as _ } from '../../deps'

const NOW = firebase.database.ServerValue.TIMESTAMP;

export class RoomModel {
  constructor (roomDb, roomKey, userKey) {
    this.roomDb = roomDb;
    this._roomKey = roomKey;
    this._round = null;
    this._roundIndex = null;
    this._userKey = userKey;

    _.bindAll(this, 'handleUpdate');
  }
  removeDbListeners () {
    this.roomDb.off();
  }
  doneGameEnd () {
    return this._round.doneRoundEnd();
  }
  doneRoundEnd () {
    return this.roomDb
      .update({[`rounds/${this._roundIndex + 1}/answerAt`]: NOW})
      .then(() => this._round.doneRoundEnd());
  }
  doneRoundEndScoreboardPlayer(scoreboardPlayerKey) {
    return this._round.doneRoundEndScoreboardPlayer(scoreboardPlayerKey);
  }
  setRound (roundIndex) {
    if (roundIndex == null) {
      this._roundIndex = null;
      this._round = null;
      return;
    }
    this._roundIndex = roundIndex;
    let roundDb = this.roomDb.child(`rounds/${roundIndex}`);
    this._round = new RoundModel(roundDb, roundIndex, this._userKey);
  }
  setOnUpdate (onUpdate) {
    this.removeDbListeners();
    return this.roomDb.on('value', (snap) => {
      console.log(`roomModel.onUpdate`, snap.val());
      return this.handleUpdate(snap.val(), onUpdate);
    });
  }
  handleUpdate (room, onUpdate) {
    let roundIndex = (room.rounds || []).findIndex(round => {
      return _.size(round.doneAt || {}) < _.size(room.players)
    });
    roundIndex = (roundIndex >= 0) ? roundIndex : null;
    if (roundIndex !== (this._round || {}).index) this.setRound(roundIndex);
    return onUpdate(room, roundIndex);
  }
  submitName (name) {
    console.log(`submitName`, name);
    return this.roomDb
      .update({
        [`players/${this._userKey}/name`]: name,
        [`players/${this._userKey}/nameAt`]: NOW
      });
  }
  submitAllPlayersPresent () {
    return this.roomDb
      .update({
        [`allPlayersPresentAt`]: NOW
      });
  }
  async updateOnline () {
    await this.roomDb.update({
      //[`players/${this._userKey}/joinAt`]: NOW,
      [`players/${this._userKey}/online`]: true
    });
    await this.roomDb.child(`players/${this._userKey}/joinAt`)
      .transaction(prevJoinAt => {
        if (prevJoinAt) return;
        else return NOW;
      });
    return;
  }
  get key () { return this._roomKey; }
  get roomCode () { return this._roomKey && roomCodeUtil.fromKey(this._roomKey); }
  get round () { return this._round; }
}
