import * as Comp from './component'
import {propTypes as $p, React, underscore as _} from '../deps'

import './Loading.css';

export default function Loading (props) {
  return (
    <Comp.Main className='Loading'/>
  )
}
Loading.displayName = 'Loading';
