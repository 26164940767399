import { Component } from '../component'
import * as history from './util/history'
import { React, underscore as _ } from '../deps'
import * as roomCodeUtil from './util/roomCode'
import * as rt from '../runtime'

export default function withRoomController (auth, client, model, Loading,
  SelectRoom, Wrapped)
{
  class RoomController extends Component {
    constructor (props) {
      super(props)
      this.initialState = {
        rejoinRoomCode: null,
        roomKey: null
      };
      this.state = {
        loaded: false,
        ...(_.clone(this.initialState))
      };

      _.bindAll(this, 
        'handleChangeAuthState',
        'handleCreateRoom',
        'handleCreateRoomInScoreboardMode',
        'handleGameEnd',
        'handleJoinRoom',
        'handlePopState',
        'handleRejoinRoom'
      );
    }
    componentDidMount () {
      this.removeChangeAuthState = auth.onAuthStateChanged(this.handleChangeAuthState);
      rt.window.addEventListener('popstate', this.handlePopState);
      client.noop().catch(err => console.error('client.prewarm', err));
    }
    componentWillUnmount () {
      rt.window.removeEventListener('popstate', this.handlePopState);
      if (this.removeChangeAuthState) {
        this.removeChangeAuthState();
        delete this.removeChangeAuthState;
      }
      model.disconnectRoom();
    }
    async connectRoom (roomKey) {
      await model.connectRoom(roomKey, null /*this.handleUpdateRoom*/);
      await this.setState({loaded: true, rejoinRoomCode: null, roomKey});
    }
    async connectUser (userCredUser) {
      let userKey = userCredUser.uid;
      await model.connectUser(userKey);
    }
    async reconnectableUserRoom () {
      let roomKey = await model.user.getUserRoomKey();
      if (!roomKey) return null;
      let roomCode = roomCodeUtil.fromKey(roomKey);
      let isReconnectable = await model.roomExistsAndNotGameOver(roomKey);
      return (isReconnectable) ? {roomCode, roomKey} : null;
    }
    async requireLogIn () {
      if (auth.currentUser) return;
      let userCred = await auth.signInAnonymously();
      await this.connectUser(userCred.user);
    }
    async reset () {
      model.disconnectRoom();
      await this.setState( _.clone(this.initialState) );
    }
    async setRejoinRoomCode () {
      let userRoom = await this.reconnectableUserRoom();
      if (!userRoom) return await this.setState({loaded: true});
      return await this.setState({loaded: true, rejoinRoomCode: userRoom.roomCode});
    }
    async tryReconnectRoom (roomCode) {
      let userRoom = await this.reconnectableUserRoom();
      if (userRoom && userRoom.roomCode === roomCode) {
        await this.connectRoom(userRoom.roomKey);
        return true;
      }
      // cannot reconnect, return to root
      history.pushRoot();
      await this.setState({loaded: true, rejoinRoomCode: (userRoom || {}).roomCode});
      return false;
    }
    handleChangeAuthState (userCredUser) {
      let urlRoomCode = roomCodeUtil.fromWindowLocation(rt.window);
      if (!userCredUser) {
        if (urlRoomCode != null) history.pushRoot();
        this.setState({loaded: true});
        return console.log('auth.logout');
      }
      if (userCredUser && (userCredUser.providerId !== 'firebase' || !userCredUser.isAnonymous)) {
        console.log('auth.invalid => logout');
        return auth.signOut()
          .catch(err => console.error('handleChangeAuthState logout', err));
      }
      console.log('auth.login', userCredUser);
      return this.connectUser(userCredUser)
        .then(() => {
          let roomCode = history.getRoomCode() || urlRoomCode;
          if (roomCode) return this.tryReconnectRoom(roomCode);
          else return this.setRejoinRoomCode();
        })
        .catch(err => console.error('handleChangeAuthState login', err));
    }
    handleCreateRoom (component) {
      (async () => {
        await this.requireLogIn();
        let {roomCode, roomKey} = await client.createRoom();
        await this.connectRoom(roomKey);
        history.pushRoom(roomCode);
      })()
        .catch(err => console.error('handleCreateRoom', err));
    }
    handleCreateRoomInScoreboardMode (component) {
      (async () => {
        await this.requireLogIn();
        let {roomCode, roomKey} = await client.createRoomInScoreboardMode();
        await this.connectRoom(roomKey);
        history.pushRoom(roomCode);
      })()
        .catch(err => console.error('handleCreateRoomInScoreboardMode', err));
    }
    handleGameEnd () {
      return this.reset()
        .then(() => history.pushRoot())
        .catch(err => console.error('handleDoneRoundEnd gameOver', err));
    }
    handleJoinRoom (component, roomCode) {
      (async () => {
        await this.requireLogIn();
        roomCode = roomCodeUtil.norm(roomCode);
        try {
          let {roomKey} = await client.joinRoom(roomCode);
          await this.connectRoom(roomKey);
          history.pushRoom(roomCode);
        }
        catch (err) {
          if ((err.code || {}).code === 'roomCode.invalid') {
            return await component.setState({error: 'invalid room code'});
          }
          if ((err.code || {}).code === 'room.cannotJoin') {
            return await component.setState({error: 'room cannot be joined'});
          }
          if (err && err.message && err.message === 'room.atCapacity') {
            return await component.setState({error: 'room is full'});
          }
          throw err;
        }
      })()
        .catch(err => {
          console.error('handleJoinRoom', roomCode, err)
          return component.setState({error: 'error joining room', joiningGame: false})
        });
    }
    handlePopState (e) {
      console.log('popstate', e.state);
      let destRoomCode = history.getRoomCode(e);
      let roomCode = (model.room || {}).roomCode;
      if (destRoomCode !== roomCode) {
        if (destRoomCode) {
          return this.requireLogIn()
            .then(() => this.tryReconnectRoom(destRoomCode))
            .catch(err => console.error('handlePopState reconnect', err));
        }
        else {
          return this.reset()  // at '/'
            .then(() => this.setRejoinRoomCode())
            .catch(err => console.error('handlePopState reset', err));
        }
      }
    }
    handleRejoinRoom (component) {
      let roomCode = this.state.rejoinRoomCode;
      history.pushRoom(roomCode);
      this.tryReconnectRoom(roomCode)
        .then(didReconnect => {
          if (!didReconnect) component.setState({error: 'error joining room'});
        })
        .catch(err => console.error('handleRejoinRoom', roomCode, err));
    }
    render () {
      let {connected} = this.props;
      let {loaded, roomKey} = this.state;

      if (!loaded) return <Loading/>;
      else if (!roomKey) {
        return <SelectRoom
          rejoinRoomCode={this.state.rejoinRoomCode}
          onCreateRoom={this.handleCreateRoom}
          onCreateRoomInScoreboardMode={this.handleCreateRoomInScoreboardMode}
          onJoinRoom={this.handleJoinRoom}
          onRejoinRoom={this.handleRejoinRoom}
          {...this.props}/>;
      }
      else {
        let roomCode = roomCodeUtil.fromKey(roomKey);
        return <Wrapped
          roomCode={roomCode}
          roomKey={roomKey}
          onGameEnd={this.handleGameEnd}
          {...this.props}/>;
      }
    }
  }
  RoomController.displayName = `RoomController(${SelectRoom.displayName}, ${Wrapped.displayName})`;
  return RoomController;
}
