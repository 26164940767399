import {cardDeck} from './img';
import * as Comp from './component'
import { propTypes as $p, React, underscore as _ } from '../deps'
import ReactGA from 'react-ga';

export default class SelectDeck extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      cardSetKey: null,
      cardSetName: null,
      startingGame: false,
    };
    _.bindAll(this,
      'handleClickCardSet',
      'handleCloseCardSet',
      'handleClickStart'
    );
  }
  handleClickCardSet (csKey, csName) {
    this.setState({
      cardSetKey: csKey,
      cardSetName: csName
    });
  }
  handleCloseCardSet (e) {
    this.setState({
      cardSetKey: null,
      cardSetName: null
    });
  }
  handleClickStart (e) {
    const { cardSetKey, cardSetName } = this.state;
    ReactGA.event({
      category: 'Decks',
      action: `Deck-Select-${cardSetName}`,
      label: `Select Deck: ${cardSetName}`,
      value: 1
    });
    this.props.onStartGame(cardSetKey);
    this.setState({
      startingGame: true,
    });
  }
  
  render() {
    let {cardSets, connected, leaderPlayerKey, players, roomCode, roundSeed, selfPlayerKey} = this.props;
    let isLeader = leaderPlayerKey === selfPlayerKey;
    let playerCount = _.size(players);
    let leader = players[leaderPlayerKey];

    let { cardSetKey, startingGame } = this.state;

    let sortedCardSets = _.chain(cardSets)
      .map((cs, key) => _.extend({}, cs, {key}))
      .sortBy('name')
      .value();

    return (
      <Comp.Main
        className='SelectDeck'
        overlay={
          this.props.connected ? 
            <Comp.Dialog
              cardDeck
              disabled={!cardSetKey}
              onClose={this.handleCloseCardSet}
            >
              <div className="deck-overlay">
                <div 
                  style={{
                    minHeight: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <h2>{cardSets && cardSetKey && cardSets[cardSetKey] ? cardSets[cardSetKey].name : ""}</h2>
                  {cardSets && cardSetKey && cardSets[cardSetKey] && cardSets[cardSetKey].graphic &&
                    <img
                      alt=""
                      role="presentation"
                      src={cardSets[cardSetKey].graphic}
                      style={{
                        margin: '10px auto',
                        maxWidth: '60%',
                        maxHeight: '400px',
                      }}
                    />
                  }
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginTop: '30px',
                  }}>
                    <input 
                      type='button'
                      value='Play'
                      onClick={this.handleClickStart}
                      disabled={startingGame}
                    />
                    {startingGame &&
                      <span style={{
                        textAlign: 'center',
                        fontSize: 16,
                        fontStyle: 'italic',
                      }}>
                        Starting game...
                      </span>
                    }
                  </div>
                </div>
              </div>
            </Comp.Dialog>
          : <Comp.OfflineOverlay disabled={connected} />
        }
      >

        <Comp.Header/>

        {(isLeader) ? (
          <React.Fragment>
            <h1>Select a Deck</h1>
            {(_.size(cardSets) < 2) ? null : (
              <div className="deck-container">
                {sortedCardSets.map((cs, i) => (
                  <button type="button" className="deck" key={i} onClick={() => this.handleClickCardSet(cs.key, cs.name)}>
                    <h2 className="deck-name">{cs.name}</h2>
                    {cs.graphic &&
                      <img
                        alt=""
                        role="presentation"
                        src={cs.graphic}
                        style={{
                          margin: '10px auto',
                          maxWidth: '60%',
                        }}
                      />
                    }
                  </button>
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>

            <h1>
              {(leader.name) ? <Comp.Player player={leader}/> : 'The leader'}{' '}
              is selecting a card deck
            </h1>

            <img
              alt="deck of cards"
              src={cardDeck.src}
              style={{
                margin: '0 auto 48px auto',
                height: cardDeck.height / 2,
                width: cardDeck.width / 2
              }}/>

            <hr style={{marginBottom: 32}}/>

          </React.Fragment>
        )}

      </Comp.Main>
    );
  }
}

SelectDeck.displayName = `SelectDeck`;