import {
  camera,
  caretrightButton,
  email,
  instagram,
  glasses,
  logomain,
  train,
  twitter,
  typewriter
} from './img';
import * as Comp from './component'
import {propTypes as $p, React, underscore as _} from '../deps'
import * as roomCodeUtil from './util/roomCode'

export default class SelectRoom extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      error: null,
      roomKey: null,
      showInstructions: false,
      showCreateGame: false,
      showCreateGameInScoreboardMode: false,
      creatingGame: false,
      creatingGameInScoreboardMode: false,
      joiningGame: false,
    };
    _.bindAll(this,
      'handleChangeRoomKey',
      'handleClickRejoin',
      'handleSubmitJoin',
      'handleClickCreate',
      'handleClickCreateInScoreboardMode',
      'handleClickHideInstructions',
      'handleClickShowInstructions',
      'handleClickHideCreateGame',
      'handleClickShowCreateGame',
      'handleClickHideCreateGameInScoreboardMode',
      'handleClickShowCreateGameInScoreboardMode'
    );
  }
  handleChangeRoomKey (e) {
    this.setState({
      roomKey: roomCodeUtil.toDisplay(e.target.value),
      error: null,
      joiningGame: false,
    });
  }
  handleClickRejoin (e) {
    this.props.onRejoinRoom(this)
  }
  handleSubmitJoin (e) {
    e.preventDefault();
    if (!roomCodeUtil.isValid(this.state.roomKey)) {
      this.setState({
        error: 'invalid room key',
        joiningGame: false,
      });
    } else {
      this.props.onJoinRoom(this, this.state.roomKey);
      this.setState({
        error: null,
        joiningGame: true,
      });
    }
  }
  handleClickCreate (e) {
    this.props.onCreateRoom(this)
    this.setState({
      creatingGame: true,
    });
  }
  handleClickCreateInScoreboardMode (e) {
    this.props.onCreateRoomInScoreboardMode(this)
    this.setState({
      creatingGameInScoreboardMode: true,
    });
  }
  handleClickHideInstructions (e) {
    this.setState({showInstructions: false});
  }
  handleClickShowInstructions (e) {
    this.setState({showInstructions: true});
  }
  handleClickHideCreateGame (e) {
    this.setState({
      showCreateGame: false,
      showCreateGameInScoreboardMode: false
    });
  }
  handleClickShowCreateGame (e) {
    this.setState({showCreateGame: true});
  }
  handleClickHideCreateGameInScoreboardMode (e) {
    this.setState({showCreateGameInScoreboardMode: false});
  }
  handleClickShowCreateGameInScoreboardMode (e) {
    this.setState({showCreateGameInScoreboardMode: true});
  }
  render () {
    let rejoinRoomCode = this.props.rejoinRoomCode
      && roomCodeUtil.toDisplay(this.props.rejoinRoomCode);
    return (
      <Comp.Main
        className='SelectRoom'
        overlay={!this.props.connected
          ? <Comp.OfflineOverlay disabled={this.props.connected} />
          : <Comp.Dialog
              disabled={!this.state.showInstructions && !this.state.showCreateGame}
              onClose={this.state.showInstructions ? this.handleClickHideInstructions : this.handleClickHideCreateGame}
            >
              {this.state.showInstructions &&
                <div>
                  <p>Thanks for testing the Believable Lies beta. Instructions are as follows:</p>
                  <br />
                  <img className="help-image" src={glasses.src} alt="" width="132px" />
                  <h2>Setting Up</h2>
                  <p>Believable Lies is a game for friends who are hanging out. It works in real life, on video chat, or even on a phone call.</p>
                  <br />
                  <p>3 to 6 players is ideal, but you can play with as few as 2 and as many as 8.</p>
                  <br />
                  <p>All players need a device with a web browser like, say, a smartphone.</p>
                  <br />
                  <p>One player creates a game, then shares a room code so others can join.</p>
                  <br />
                  <img className="help-image" src={typewriter.src} alt="" width="132px" />
                  <h2>The Game</h2>
                  <p>The goal of the game is to write believable lies about the given topic.</p>
                  <br />
                  <p>Then, see if you can identify the truth from amongst your friends' lies.</p>
                  <br />
                  <p>You get points for identifying the truth, and you also get points when people guess your lie.</p>
                  <br />
                  <p><strong>One Last Rule:</strong> If a player submits a lie that is later proven to be true after the game, they retroactively lose the game. If they had already lost, then who cares.</p>
                  <br />
                  <button
                    type='button'
                    style={{
                      margin: '30px auto',
                    }}
                    onClick={this.handleClickHideInstructions}
                  >
                    BACK TO MAIN MENU
                  </button>
                </div>
              }
              {this.state.showCreateGame && !this.state.showCreateGameInScoreboardMode &&
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <img
                    alt=""
                    role="decorative"
                    src={train.src}
                    style={{
                      height: train.height / 2,
                      margin: '20px auto',
                      width: train.width / 2
                    }}
                  />
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#241C15',
                      fontFamily: 'StanleyRegular, Georgia, serif',
                      fontSize: 32,
                      marginBottom: (this.state.creatingGame ? 0 : 30),
                      textTransform: 'none',
                    }}
                    type='button'
                    onClick={this.handleClickCreate}
                    disabled={this.state.creatingGame}
                  >
                    Start Game{'\u00a0>'}
                  </button>
                  {this.state.creatingGame &&
                    <span style={{
                      textAlign: 'center',
                      fontSize: 16,
                      fontStyle: 'italic',
                      margin: '6px auto',
                    }}>
                      Creating game...
                    </span>
                  }
                  <img
                    alt=""
                    role="decorative"
                    src={camera.src}
                    style={{
                      height: camera.height / 2,
                      margin: '20px auto',
                      width: camera.width / 2
                    }}
                  />
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#241C15',
                      fontFamily: 'StanleyRegular, Georgia, serif',
                      fontSize: 32,
                      marginBottom: (this.state.creatingGameInScoreboardMode ? 0 : 30),
                      textTransform: 'none',
                    }}
                    type='button'
                    onClick={this.handleClickShowCreateGameInScoreboardMode}
                  >
                    Start Game in Scoreboard Mode for Streamers{'\u00a0>'}
                  </button>
                  {this.state.creatingGameInScoreboardMode &&
                    <span style={{
                      textAlign: 'center',
                      fontSize: 16,
                      fontStyle: 'italic',
                      margin: '6px auto',
                    }}>
                      Creating game in scoreboard mode...
                    </span>
                  }
                </div>
              }
              {this.state.showCreateGame && this.state.showCreateGameInScoreboardMode &&
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <p>Scoreboard mode is an advanced feature designed for people who stream games!</p>
                  <br />
                  <p>Starting a game in scoreboard will make this device act as a scoreboard for all players to see.</p>
                  <br />
                  <p>You cannot participate from the device running scoreboard mode, to play you will need to join from a separate device. Scoreboard mode just provides a neutral overview of what's happening in the game.</p>
                  <br />
                  <p>Scoreboard mode works best on <strong>large screens</strong>, such as computers or tablets rotated horizontally, with a 16:9 aspect ratio.</p>
                  <br />
                  <button
                    style={{
                      background: 'none',
                      border: 'none',
                      color: '#241C15',
                      fontFamily: 'StanleyRegular, Georgia, serif',
                      fontSize: 32,
                      marginBottom: (this.state.creatingGameInScoreboardMode ? 0 : 30),
                      textTransform: 'none',
                    }}
                    type='button'
                    onClick={this.handleClickCreateInScoreboardMode}
                    disabled={this.state.creatingGameInScoreboardMode}
                  >
                    Start Game in Scoreboard Mode for Streamers{'\u00a0>'}
                  </button>
                  {this.state.creatingGameInScoreboardMode &&
                    <span style={{
                      textAlign: 'center',
                      fontSize: 16,
                      fontStyle: 'italic',
                      margin: '6px auto',
                    }}>
                      Creating game in scoreboard mode...
                    </span>
                  }
                  <button
                    type='button'
                    style={{
                      margin: '20px auto',
                    }}
                    onClick={this.handleClickHideCreateGameInScoreboardMode}
                  >
                    CANCEL
                  </button>
                </div>
              }
            </Comp.Dialog>
          }
        >

        <Comp.Img.Aspect
          alt='Believable Lies'
          img={logomain}
          style={{
            marginBottom: 30,
            width: '100%',
            zIndex: (this.state.showInstructions || !this.props.connected || this.state.showCreateGame ? -1 : 0)
          }}
        />

        <p style={{ marginTop: 40 }}>A short game for 2-8 players.</p>

        <img
          src={typewriter.src}
          style={{
            height: typewriter.height / 2,
            margin: '20px auto',
            maxWidth: '50%',
            width: typewriter.width / 2
          }}
        />

        {(!rejoinRoomCode) ? null : (
          <div style={{
              border: 'solid #000',
              borderWidth: '1px 0',
              marginBottom: 32,
              marginTop: 16,
              padding: '30px 16px'
            }}>

            <div style={{
                marginBottom: 16,
                textAlign: 'center'
              }}>

              Your game in room{' '}

              <span>{rejoinRoomCode}</span>{' '}

              is still in progress

            </div>

            <input style={{
                margin: '0 auto'
              }}
              type="button"
              value="Rejoin Game"
              onClick={this.handleClickRejoin}/>

          </div>
        )}

        <button
          style={{
            background: 'none',
            border: 'none',
            color: '#241C15',
            fontFamily: 'StanleyRegular, Georgia, serif',
            fontSize: 32,
            marginBottom: (this.state.creatingGame ? 6 : 24),
            textTransform: 'none',
          }}
          type='button'
          onClick={this.handleClickShowCreateGame}
          disabled={this.state.creatingGame}
        >
          Create{'\u00a0'}Game{'\u00a0>'}
        </button>

        <div className='flex'
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            fontSize: 32,
            fontStyle: 'italic',
            marginBottom: 24
          }}>

          <div style={{
              border: 'solid #000',
              borderWidth: '1px 0 0 0',
              flex: 1,
              height: 1
            }}/>

          <div style={{margin: '0 30px'}}>
            or
          </div>

          <div style={{
              border: 'solid #000',
              borderWidth: '1px 0 0 0',
              flex: 1,
              height: 1
            }}/>

        </div>

        <div style={{fontSize: 32, marginBottom: 16, textAlign: 'center', width: '100%'}}>
          Join a Game
        </div>

        <form onSubmit={this.handleSubmitJoin}
          style={{marginBottom: 16}}>

          <div className='flex'
            style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative'
            }}>

            <input className={`code ${Comp.errorClass(this)}`}
              placeholder='Enter Room Code'
              style={{
                fontSize: '28px',
                zIndex: (this.state.showInstructions || !this.props.connected || this.state.showCreateGame ? -1 : 0)
              }}
              maxLength={6}
              size={6}
              type='text'
              value={this.state.roomKey || ''}
              onChange={this.handleChangeRoomKey}/>

            <button
              style={{
                backgroundColor: '#B53B3C',
                border: 'none',
                bottom: 0,
                display: (this.state.roomKey || '').length < 1 ? 'none' : null,
                minWidth: 50,
                position: 'absolute',
                right: 0,
                top: 0,
                width: 50,
                zIndex: (this.state.showInstructions || !this.props.connected || this.state.showCreateGame ? -1 : 0)
              }}
              disabled={this.state.joiningGame}
            >

              <img src={caretrightButton.src}
                style={{
                  display: 'block',
                  height: caretrightButton.height / 2,
                  margin: 'auto',
                  width: caretrightButton.width / 2
                }}/>

            </button>

          </div>

          <Comp.ErrorInfo error={this.state.error}/>

          {this.state.joiningGame && !this.state.error &&
            <span style={{
              textAlign: 'center',
              fontSize: 16,
              fontStyle: 'italic',
            }}>
              Joining game...
            </span>
          }
        </form>

        <div style={{flex: '1 96px'}}/>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            fontSize: 16,
            fontStyle: 'italic'
          }}
        >

          <a
            href="#"
            onClick={this.handleClickShowInstructions}
            style={{
              marginRight: '20px',
              marginBottom: '16px'
            }}
          >
            Instructions{'\u000a>'}
          </a>

          <span className="contact-info">
            <a
              href='mailto:info@liesgame.com'
              target='_blank'
              rel="noopener noreferrer"
            >
              <img className="contact-image" src={email.src} alt="email" />
            </a>
            <a
              href="https://twitter.com/liesgamedotcom"
              target='_blank'
              rel="noopener noreferrer"
            >
              <img className="contact-image" src={twitter.src} alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/liesgamedotcom/"
              target='_blank'
              rel="noopener noreferrer"
            >
              <img className="contact-image" src={instagram.src} alt="instagram" />
            </a>
          </span>

        </div>

      </Comp.Main>
    )
  }
}
SelectRoom.displayName = `SelectRoom`;
