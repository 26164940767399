import { underscore as _ } from '../../deps'

export class ConnectedModel {
  constructor (db) {
    this.db = db.root.child('.info/connected');
  }
  setOnUpdate (onUpdate) {
    this.removeDbListeners();
    this.db.on('value', snap => onUpdate(snap.val()));
  }
  removeDbListeners () { this.db.off() }
}
