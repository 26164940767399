import { propTypes as $p, React, underscore as _ } from '../../deps'
import {has, resetScroll, setAwait} from '../util/runtime';

export class Main extends React.Component {
  componentDidMount () {
    this.onClassName(this.props.className);
    resetScroll();
  }
  componentDidUpdate (prevProps) {
    let {props: {className}} = this;
    if (className !== prevProps.className) this.onClassName(this.props.className);
  }
  onClassName (className) {
    setAwait(has(className, 'await'));
  }
  render () {
    let {props} = this;
    let {overlay, style, ...thruProps} = props;
    return (
      <React.Fragment>

        {overlay}

        {this.props.className === "Scoreboard"
          ? <main
            id="main-content"
            style={{
              ...style
            }}
            {...thruProps}
          />
          : <main
            id="main-content"
            style={{
              padding: '30px',
              ...style
            }}
            {...thruProps}
          />
        }

      </React.Fragment>
    );
  }
}
Main.displayName = `Main`;
