import * as rt from '../runtime'

export default function hostConfig (key) {
  return (hostConfig.config[rt.window.location.hostname] || {})[key] ||
    hostConfig.default(key);
}

hostConfig.default = (key) => hostConfig.config['default'][key];

hostConfig.config = {
  'believable-lies.firebaseapp.com': {
    authPersist: 'local',
    dbRootKey: 'prod',
    requireHostAuth: false
  },
  'liesgame.com': {
    authPersist: 'local',
    dbRootKey: 'prod',
    requireHostAuth: false
  },
  'www.liesgame.com': {
    authPersist: 'local',
    dbRootKey: 'prod',
    requireHostAuth: false
  },
  default: {
    authPersist: 'session',
    dbRootKey: 'dev',
    requireHostAuth: false
  }
};
