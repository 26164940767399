import firebase from '../../firebase'

const NOW = firebase.database.ServerValue.TIMESTAMP;

export class UserModel {
  constructor (userDb, userKey) {
    this.userDb = userDb;
    this._userKey = userKey;
  }
  updateOnline () {
    return this.userDb
      .update({
        online: true,
        lastOnlineAt: NOW
      });
  }
  prepareUpdateOffline () {
    return this.userDb
      .onDisconnect()
      .update({
        online: null,
        lastOnlineAt: NOW
      });
  }
  getUserRoomKey () {
    return this.userDb.child('_/roomKey')
      .once('value')
      .then(snap => snap.val());
  }
  getUserPacks () {
    return this.userDb.child('_/packs')
      .once('value')
      .then(snap => snap.val());
  }
  get key () { return this._userKey; }
}
