import * as fmt from '../util/fmt'
import { propTypes as $p, React, underscore as _ } from '../../deps'
import { PlayerSeries } from './Player'
import Timer from '../util/timer'

export class Countdown extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isExpired: false,
      timeLeft: props.timeout
    };
    _.bindAll(this, 'handleTick');
    this.timer = new Timer(
      _.assign({onTick: this.handleTick}, props)
    );
  }
  componentDidMount () { this.timer.start(); }
  componentWillUnmount () { this.timer.stop(); }
  handleTick () {
    this.setState({
      isExpired: this.timer.isExpired,
      timeLeft: this.timer.timeLeft
    });
  }
  render () {
    let { answered, answering, answersLeft, scoreboardAnswering, scoreboardVoting, timeout, voted, voting, votesLeft } = this.props;
    return (!this.timer.isTimeoutFinite) ? null :
      (this.state.isExpired) ?
        (<div className={`countdown expired ${scoreboardAnswering || scoreboardVoting ? "scoreboard" : ""}`}>
          {answering &&
            <span role="alert">Time up! You can still answer, but now other players can make fun of you.</span>
          }
          {answered &&
            <span role="alert">You are now allowed to make fun of anyone still writing a lie.</span>
          }
          {voting &&
            <span role="alert">Time up! You can still vote, but now other players can make fun of you.</span>
          }
          {voted &&
            <span role="alert">You are now allowed to make fun of anyone still voting.</span>
          }
          {scoreboardAnswering &&
            <span role="alert">Time up! Anyone who has already answered can now make fun of <PlayerSeries players={answersLeft || {}} />.</span>
          }
          {scoreboardVoting && 
            <span role="alert">Time up! Anyone who has already voted can now make fun of <PlayerSeries players={votesLeft || {}} />.</span>
          }
        </div>) :
        (<div className={`countdown ${scoreboardAnswering || scoreboardVoting ? "scoreboard" : ""}`}>
          <div className="countdown-copy" role="alert">
            <span className={`countdown-small ${scoreboardAnswering || scoreboardVoting ? "scoreboard" : ""}`}>Meaningless Timer</span>
            <span className="countdown-total-time">{timeout} seconds</span>
          </div>
          <br />
          <span
            className={`countdown-timer ${scoreboardAnswering || scoreboardVoting ? "scoreboard" : ""}`}
            data-timeout={timeout}
            data-time={this.state.timeLeft}>
            {fmt.num(this.state.timeLeft)}
          </span>
          <br />
          <span className={`countdown-small ${scoreboardAnswering || scoreboardVoting ? "scoreboard" : ""}`}>
            {this.state.timeLeft === 1 ? "second" : "seconds"}
          </span>
        </div>);
  }
}
Countdown.displayName = `Countdown`;
