import { canonicalHost, canonicalUrl } from '../../util/runtimeConfig'
import {Hr} from './Hr';
import { propTypes as $p, React, underscore as _ } from '../../deps'
import * as roomCodeUtil from '../util/roomCode'

export const RoomCode = (props) => (
  <input className='code'
    readOnly
    size='6'
    style={props.style}
    type='text'
    value={roomCodeUtil.toDisplay(props.roomCode)}/>
);
RoomCode.displayName = `RoomCode`;

export function RoomCodeHeader (props) {
  return (
    <React.Fragment>

      <h1>
        Room Code
      </h1>

      <RoomCode roomCode={props.roomCode}
        style={{marginBottom: 24}}/>

      <UrlTip {...props}
        style={{
          marginBottom: 24,
          marginTop: 0
        }}/>

      <hr
        style={{
          marginBottom: 32,
          ...props.style
        }}/>

    </React.Fragment>
  );
}
RoomCodeHeader.displayName = `RoomCodeHeader`;

export class UrlTip extends React.Component {
  constructor (props) {
    super(props);
    _.bindAll(this, 'handleClickLink');
  }
  handleClickLink (e) {
    if (e.button === 0) e.preventDefault();
  }
  render () {
    return /*(this.props.selfPlayerKey !== this.props.leaderPlayerKey) ? null :*/ (
      <p style={{
          fontSize: 17,
          lineHeight: '22px',
          textAlign: 'center',
          ...this.props.style
        }}>

        Tell your friends to go to{' '}

        <a href={canonicalUrl}
          className="game-url"
          onClick={this.handleClickLink}
          style={{
            fontStyle: 'italic',
            textDecoration: 'underline'
          }}>

          {canonicalHost}
        </a>{' '}

        and enter the room code

      </p>
    );
  }
}
UrlTip.displayName = `UrlTip`;
